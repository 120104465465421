import { useState, CSSProperties, useEffect, useRef } from 'react';
import { useNavigate, useLoaderData, Navigate, useSearchParams, Link } from 'react-router-dom';
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  DropDownProps,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Progress,
  Result,
  Row,
  Select,
  Space,
  Switch,
  Table,
  TableProps,
  Tabs,
  Tag,
  Tooltip,
  Upload,
  UploadProps,
  Typography,
} from 'antd';
import { EditOutlined, EyeOutlined, PlusCircleFilled, SearchOutlined } from '@ant-design/icons';
import { LoginForm, ProFormCaptcha, ProFormCheckbox, ProFormText } from '@ant-design/pro-components';
import { store, useAppDispatch, useAppSelector } from '../../store';
import { selectAuthState, loginByUserPassThunk, getUserInfoThunkAction } from '../../reduxs/authSlice';
import { selectLanuageState, selectThemeState } from '../../reduxs/themeSlice';
import { useTranslation } from 'react-i18next';
import { loginGoogleApi } from '../../apis/authApi';
import Loading, { LoadingComponent } from '../../components/loading';
import { GPT_MODEL_3_5, GPT_MODEL_3_5_TURBO, GPT_MODEL_4, GPT_MODEL_4_TURBO, LANGUAGES, RAG_STRATEGY } from '../../consts/constApp';
import { formatBytes, getCurrentBot, keyToTitle } from '../../utils/appHelper';
import {
  addVectorDataApi,
  getAllBotApi,
  getBotByIdApi,
  getProviderApi,
  listDocumentApi,
  removeDocumentApi,
  retrainVectorDataApi,
  searchVectorDataApi,
  updateBotApi,
  uploadTrainDocument,
} from '../../apis/appApi';
import { MenuProps } from 'antd/lib';
import { BotDataType } from '../../types/appTypes';
import CurrentBotComponent from '../../components/currentBot/currentBotComponent';
import { selectAppState, setCurrentBotReduxAction } from '../../reduxs/appSlice';
import BotCreateComponent from '../../components/botCreate/botCreatecomponent';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DeltaStatic } from 'quill';
import Search from 'antd/lib/input/Search';

const Paragraph = Typography.Paragraph;
const styleBreakline: any = {
  whiteSpace: 'pre-line',
  overflowWrap: 'break-word',
  wordBreak: 'break-word',
};
const VectorDataListPage = () => {
  const { t, i18n } = useTranslation();
  const userInfo = useLoaderData();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuthState);
  const appState = useAppSelector(selectAppState);
  const themeState = useAppSelector(selectThemeState);
  const lang = useAppSelector(selectLanuageState);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [vectorDataItems, setVectorDataItems] = useState<any>([]);

  const [showModalView, setShowModalView] = useState<any>(false);
  const [showModalEdit, setShowModalEdit] = useState<any>(false);
  const [showModalAdd, setShowModalAdd] = useState<any>(false);
  const [currentVectorData, setCurrentVectorData] = useState(null as any);

  const [botItems, setBotItems] = useState<any>([]);
  const [botData, setBotData]: [BotDataType, any] = useState((getCurrentBot() as BotDataType) || {});

  const columns: TableProps<any>['columns'] = [
    {
      title: keyToTitle('text'),
      dataIndex: 'text',
      key: 'text',
      render: (text, record) => (
        <>
          <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: 'more' }} style={{ ...styleBreakline }}>
            {String(record.originText).toString()}
          </Paragraph>
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title={'Edit'}>
            <EditOutlined
              onClick={(e) => {
                setShowModalEdit(true);
                setCurrentVectorData(record);
              }}
              style={{ color: 'orange' }}
            />
          </Tooltip>

          <Tooltip title={'View'}>
            <EyeOutlined
              onClick={(e) => {
                setCurrentVectorData(record);
                setShowModalView(true);
              }}
              style={{ color: 'blueviolet' }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  const loadAllBotItems = async () => {
    const setBotDataCustom = (data: any) => {
      setBotData(data);
      dispatch(setCurrentBotReduxAction(data));
    };
    setLoading(true);
    const [result] = await Promise.all([getAllBotApi()]);
    let allBotItems = result.items;
    setBotItems(allBotItems as any);
    if (!botData || !botData.id) {
      setBotDataCustom(allBotItems[0]);
    } else if (botData) {
      if (botData?.id !== appState.currentBotInstance?.id) {
        setBotDataCustom(appState.currentBotInstance);
      }
    }
    setLoading(false);
  };
  const loadVectorData: any = async (params: any = null) => {
    const { page: pageParam, limit: limitParam, keyword } = params || {};
    setLoading(true);
    const [result] = await Promise.all([
      searchVectorDataApi({
        botId: botData?.id,
        q: keyword || searchValue,
        page: pageParam || page,
        limit: limitParam || limit,
      }),
    ]);
    console.log(`vector data `, result);
    let total = result.total;
    let items = result.items;
    setTotal(total);
    setVectorDataItems(
      items.map((r: any) => {
        return {
          ...r,
          originText: r.text,
        };
      })
    );
    setLoading(false);
  };
  useEffect(() => {
    loadAllBotItems();
    loadVectorData({});
  }, []);

  const submitSaveCurrentVectorData = async (params: any = null) => {
    const { action } = params || {};
    let isNewVector = action === 'new';
    if (loading) return;
    setLoading(true);
    if (isNewVector) {
      try {
        await addVectorDataApi({
          botId: botData?.id,
          embeddingId: '',
          text: currentVectorData?.text,
        });
        message.success(`Add vector data success`);
        await loadVectorData();
        setShowModalAdd(false);
      } catch (error: any) {
        console.error(error);
        message.error(JSON.stringify(error));
      }
    } else {
      try {
        await retrainVectorDataApi({
          botId: botData?.id,
          embeddingId: currentVectorData?.embeddingId,
          text: currentVectorData?.text,
        });
        message.success(`Update vector data success`);
        await loadVectorData();
        setShowModalEdit(false);
      } catch (error: any) {
        console.error(error);
        message.error(JSON.stringify(error));
      }
    }

    setLoading(false);
  };

  return (
    <div className="list-bot-instance">
      <Card
        title={
          <CurrentBotComponent
            allBotItems={botItems}
            // callback={(selectBot:any) => {
            //   if(selectBot)
            //   setBotData(selectBot);
            // }}
          />
        }
      >
        {loading && <LoadingComponent />}

        <div className="list">
          <Row style={{ marginBottom: '15px' }}>
            <Col span={12}>
              <Search
                placeholder="input search text"
                loading={loading}
                allowClear={true}
                onSearch={async (value, _e, info) => {
                  console.log(`on change search`, value);
                  setSearchValue(value);
                  await loadVectorData({
                    keyword: value,
                  });
                }}
                enterButton
              />
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <div className="text-right">
                <Button
                  type="primary"
                  onClick={(e) => {
                    setShowModalAdd(true);
                    setCurrentVectorData({});
                  }}
                >
                  <PlusCircleFilled />
                  Add Vector Data
                </Button>
              </div>
            </Col>
          </Row>

          <Table
            columns={columns}
            dataSource={vectorDataItems.map((r: any, idx: number) => {
              return {
                ...r,
                key: r.idx + String(idx),
              };
            })}
            pagination={false}
            style={{ marginBottom: '15px' }}
          />
          <Pagination
            current={page}
            pageSize={limit}
            total={total}
            onChange={(pageCb, pageSizeCb) => {
              console.log(`change page`, pageCb, pageSizeCb);
              if (limit !== pageSizeCb) {
                setLimit(pageSizeCb);
              }
              setPage(pageCb);
              loadVectorData({ page: pageCb, limit: pageSizeCb || limit });
            }}
          />
        </div>
        {showModalView && currentVectorData && (
          <Modal
            width={'90%'}
            open={showModalView}
            title={'View vector data'}
            footer={null}
            onCancel={(e) => {
              setShowModalView(false);
            }}
          >
            <Divider />
            <Paragraph style={{ ...styleBreakline }}>{String(currentVectorData.originText).toString()}</Paragraph>
          </Modal>
        )}
        {showModalEdit && currentVectorData && (
          <Modal
            width={'90%'}
            open={showModalEdit}
            title={'Edit vector data'}
            // footer={null}
            onCancel={(e) => {
              setShowModalEdit(false);
              setCurrentVectorData(null);
            }}
            okText={'Retrain'}
            okButtonProps={{
              disabled: loading,
              style: { backgroundColor: 'red' },
            }}
            onOk={async (e) => {
              await submitSaveCurrentVectorData();
            }}
            // style={{background:'#fafafa'}}
          >
            <Divider />
            <div className="text-editor">
              {loading && <LoadingComponent />}
              <div>
                <Typography.Text>Editor</Typography.Text>
              </div>
              {currentVectorData && (
                <ReactQuill
                  theme="snow"
                  defaultValue={
                    {
                      ops: [
                        {
                          insert: String(currentVectorData.text).toString(),
                        },
                      ],
                    } as DeltaStatic
                  }
                  // value={{
                  //   ops: [{
                  //     "insert": currentVectorData.text
                  //   }]
                  // } as DeltaStatic}
                  // style={styleBreakline}
                  onChange={(v: string, delta: DeltaStatic, source: any, editor: any) => {
                    let textContent = editor?.getText() || v;
                    // console.log(`onchange data`, v);
                    // console.log(`onchange data delta=`, delta);
                    // console.log(`onchange data source=`, source);
                    // console.log(`onchange data getText=`, textContent);
                    // console.log(`onchange data editor=`, editor, `\n\n\neditor.getText()`, editor.getText(),
                    // `\n\n\n editor.getContents(): `,
                    // editor.getContents(), `\n\n\n editor.getHTML()`,editor.getHTML());
                    setCurrentVectorData({
                      ...currentVectorData,
                      text: textContent,
                    });
                  }}
                />
              )}
            </div>
          </Modal>
        )}

        {showModalAdd && currentVectorData && (
          <Modal
            width={'90%'}
            open={showModalAdd}
            title={'Add vector data'}
            // footer={null}
            onCancel={(e) => {
              setShowModalAdd(false);
              setCurrentVectorData(null);
            }}
            okText={'Add vector data'}
            okButtonProps={{
              disabled: loading,
              style: { backgroundColor: 'red' },
            }}
            onOk={async (e) => {
              await submitSaveCurrentVectorData({ action: 'new' });
            }}
          >
            <Divider />
            <div className="text-editor">
              {loading && <LoadingComponent />}
              <div>
                <Typography.Text>Editor</Typography.Text>
              </div>
              {currentVectorData && (
                <ReactQuill
                  theme="snow"
                  defaultValue={
                    {
                      ops: [
                        {
                          insert: ``,
                        },
                      ],
                    } as DeltaStatic
                  }
                  onChange={(v: string, delta: DeltaStatic, source: any, editor: any) => {
                    let textContent = editor?.getText() || v;
                    // console.log(`onchange data getText=`, textContent);
                    setCurrentVectorData({
                      ...currentVectorData,
                      text: textContent,
                    });
                  }}
                />
              )}
            </div>
          </Modal>
        )}
      </Card>
    </div>
  );
};

export default VectorDataListPage;
