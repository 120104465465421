export default {
  translation: {
    appName: 'ChatAI Admin',
    appDescription: 'Welcome to chatAI system',
    'Welcome to React': 'Welcome to React and react-i18next',
    login: 'Login',
    email: 'Email',
    username: 'Username',
    password: 'Password',
    phone: 'Phone',
    or: 'or',
    remember: 'Remember',
    forgotPassword: 'Forgot Password',
    message: {
      Selectlanguage: 'Select language',
    },
  },
};
