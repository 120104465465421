/* eslint-disable */
import { getAuthToken, getAuthUser, isAuthenticated } from '../utils/authHelper';

async function checkAuth() {
  let token = getAuthToken();
  let user = getAuthUser();
  if (token && user) {
    return { token, user };
  }
  return null;
}

async function checkIsNotAuth() {
  let token = getAuthToken();
  let user = getAuthUser();
  return !token && !user;
}

export { checkAuth, checkIsNotAuth };
