import { useState, useMemo, Suspense, useCallback } from 'react';
import { Layout, Row, Menu, Avatar, Input } from 'antd';
import { ProLayout } from '@ant-design/pro-components';
import { useLocation, Outlet, useMatches, Navigate, useLoaderData, Link, useNavigate } from 'react-router-dom';

import Loading from '../components/loading';
import HeaderLayout from './header';
// import { useEmotionCss } from '../hooks'
import { menuItems } from '../routes/routes';
import { useAppSelector } from '../store';
import { selectAuthState } from '../reduxs/authSlice';
import { UserOutlined } from '@ant-design/icons';
import { selectThemeState } from '../reduxs/themeSlice';
import { ROLE_ADMIN } from '../consts/constApp';

const { Content, Sider } = Layout;

export default function AdminLayout() {
  const userInfo: any = useLoaderData();
  // console.log('useLoaderData', userInfo)

  const location = useLocation();
  const matches = useMatches();
  const [collapsed, setCollapsed] = useState(false);
  const [defaultOpenKeys, setDefaultOpenKeys] = useState([]);
  const authState = useAppSelector(selectAuthState);
  const themeState = useAppSelector(selectThemeState);
  const navigate = useNavigate();
  const selectedKeys = [location.pathname];
  useMemo(() => {
    let arr: any = matches.slice(1, -1).map((item) => item.pathname);
    setDefaultOpenKeys(arr);
  }, [matches]);
  if (!authState.token || !authState.user) {
    console.log(`redirect to login`);
    return <Navigate replace to="/login" />;
  }

  if (!userInfo) {
    return <Navigate replace to="/login" />;
  }
  const filterMenusByRole = (): any => {
    const role = authState?.user?.role || userInfo?.role;
    let newMenuItems = [];
    // console.log(`user role`, role);
    for (let menu of menuItems) {
      // console.log(`loop menu`, menu);
      if (role == ROLE_ADMIN) {
        newMenuItems.push(menu);
      } else if (menu.type == 'group' || menu.type == 'divider') {
        newMenuItems.push(menu);
      } else if (Array.isArray(menu.roles) && menu.roles.includes(role)) {
        newMenuItems.push(menu);
      } else if (menu.roles === undefined) {
        //public route
        newMenuItems.push(menu);
      }
    }
    return newMenuItems;
  };

  return (
    <Layout style={{ minHeight: '100%', minWidth: 960 }}>
      <Sider className="ant-app-sidebar" trigger={null} theme={'light'} width={200} collapsed={collapsed} collapsible onCollapse={(value) => setCollapsed(value)}>
        <Row
          justify="center"
          style={{
            lineHeight: '48px',
            fontSize: 18,
            // paddingLeft: 10,
            paddingTop: 10,
            paddingBottom: 10,
            // borderRight: '1px solid #0505050f',
            // width:'100%'
          }}
        >
          {collapsed ? (
            <Avatar
              onClick={(e) => {
                navigate('/');
              }}
              className="sidebar-brandlogo"
              src="/images/tln-logo.png"
              style={{ cursor: 'pointer' }}
            />
          ) : (
            <span
              style={{ display: 'flex', lineHeight: '32px', width: '90%', cursor: 'pointer' }}
              onClick={(e) => {
                navigate('/');
              }}
            >
              <Avatar className="sidebar-brandlogo" src="/images/tln-logo.png" />
              <span className="sidebar-brandname">{'ChatAI Solution' || authState?.user?.username}</span>
            </span>
          )}
        </Row>
        <Row
          justify="center"
          style={{
            lineHeight: '12px',
            fontSize: 12,
            padding: '5px',
            borderRight: '1px solid #0505050f',
          }}
        >
          {collapsed ? null : <Input style={{ height: '32px' }} placeholder="Search" />}
        </Row>

        <Menu
          className="app-menu-left-sidebar"
          theme="light"
          mode="inline"
          style={{ height: 'calc(100vh - 48px)' }}
          items={filterMenusByRole()}
          selectedKeys={selectedKeys}
          defaultOpenKeys={defaultOpenKeys}
        />
      </Sider>
      <Layout>
        <HeaderLayout collapsed={collapsed} setCollapsed={setCollapsed} />
        <Content
          style={{
            background: '#f9f9f9',
            padding: 16,
            position: 'relative',
          }}
        >
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
}
