import { Button, Result } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

const enum ResultType {
  Forbidden = 403,
  NotFound = 404,
}

const ResultInfo = {
  403: '403 ',
  404: '404 ',
};

type ResultCodes = keyof typeof ResultInfo;

function ResultPage(props: { resultCode: ResultCodes }) {
  const { resultCode } = props;
  const resultInfo = ResultInfo[resultCode];
  return <Result title={resultCode} subTitle={resultInfo} extra={<Link to="/">Home</Link>} />;
}

// export default () => <ResultPage resultCode={ResultType.Forbidden} />;
export default () => {
  const navigate = useNavigate();
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      // extra={<Button type="primary">Back Home</Button>}
      extra={
        <Button
          type="primary"
          onClick={(e) => {
            navigate('/');
          }}
        >
          Back Home
        </Button>
      }
    />
  );
};
