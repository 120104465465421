import { useState, CSSProperties, useEffect, useRef } from 'react';
import { useNavigate, useLoaderData, Navigate, useSearchParams, Link } from 'react-router-dom';
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  DropDownProps,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Progress,
  Result,
  Row,
  Select,
  Space,
  Switch,
  Table,
  TableProps,
  Tabs,
  Tag,
  Tooltip,
  Upload,
  UploadProps,
  Typography,
  Badge,
  Popconfirm,
} from 'antd';
import { CheckOutlined, DeleteFilled, EditOutlined, EyeOutlined, PlusCircleFilled, PlusCircleOutlined, SearchOutlined, StopOutlined } from '@ant-design/icons';
import { LoginForm, ProFormCaptcha, ProFormCheckbox, ProFormText } from '@ant-design/pro-components';
import { store, useAppDispatch, useAppSelector } from '../../store';
import { selectAuthState, loginByUserPassThunk, getUserInfoThunkAction } from '../../reduxs/authSlice';
import { selectLanuageState, selectThemeState } from '../../reduxs/themeSlice';
import { useTranslation } from 'react-i18next';
import { loginGoogleApi } from '../../apis/authApi';
import Loading, { LoadingComponent } from '../../components/loading';
import { FeedbackStatusEnum, GPT_MODEL_3_5, GPT_MODEL_3_5_TURBO, GPT_MODEL_4, GPT_MODEL_4_TURBO, LANGUAGES, RAG_STRATEGY } from '../../consts/constApp';
import { formatBytes, getCurrentBot, keyToTitle } from '../../utils/appHelper';
import {
  addTagApi,
  addVectorDataApi,
  deleteTagApi,
  feedbackListApi,
  feedbackUpdateApi,
  getAllBotApi,
  getBotByIdApi,
  getProviderApi,
  listDocumentApi,
  removeDocumentApi,
  retrainVectorDataApi,
  searchVectorDataApi,
  tagListApi,
  updateBotApi,
  updateTagApi,
  uploadTrainDocument,
} from '../../apis/appApi';
import { MenuProps } from 'antd/lib';
import { BotDataType } from '../../types/appTypes';
import CurrentBotComponent from '../../components/currentBot/currentBotComponent';
import { selectAppState, setCurrentBotReduxAction } from '../../reduxs/appSlice';
import BotCreateComponent from '../../components/botCreate/botCreatecomponent';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DeltaStatic } from 'quill';
import Search from 'antd/lib/input/Search';

const Paragraph = Typography.Paragraph;
const styleBreakline: any = {
  whiteSpace: 'pre-line',
  overflowWrap: 'break-word',
  wordBreak: 'break-word',
};
const TagsPage = () => {
  let reactEditor = useRef(null);
  const { t, i18n } = useTranslation();
  const userInfo = useLoaderData();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuthState);
  const appState = useAppSelector(selectAppState);
  const themeState = useAppSelector(selectThemeState);
  const lang = useAppSelector(selectLanuageState);
  const [filterParams, setFilterParams] = useState({
    status: '',
  } as any);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tagDataItems, setTagDataItems] = useState<any>([]);

  const [showModalView, setShowModalView] = useState<any>(false);
  const [showModalEdit, setShowModalEdit] = useState<any>(false);
  const [showModalAdd, setShowModalAdd] = useState<any>(false);
  const [currentTagData, setCurrentTagData] = useState(null as any);

  const [botItems, setBotItems] = useState<any>([]);
  const [botData, setBotData]: [BotDataType, any] = useState((getCurrentBot() as BotDataType) || {});

  const columns: TableProps<any>['columns'] = [
    {
      title: keyToTitle('code'),
      dataIndex: 'code',
      key: 'code',
      render: (code, record) => (
        <>
          <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: 'more' }} style={{ ...styleBreakline }}>
            {String(record.code).toString()}
          </Paragraph>
        </>
      ),
    },
    {
      title: keyToTitle('name'),
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <>
          <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: 'more' }} style={{ ...styleBreakline }}>
            {String(record.name).toString()}
          </Paragraph>
        </>
      ),
    },
    {
      title: keyToTitle('tagType'),
      dataIndex: 'tagType',
      key: 'tagType',
      render: (tagType, record) => (
        <>
          <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: 'more' }} style={{ ...styleBreakline }}>
            {String(record.tagType).toString()}
          </Paragraph>
        </>
      ),
    },
    {
      title: keyToTitle('knowledgeBase'),
      dataIndex: 'knowledgeBase',
      key: 'knowledgeBase',
      render: (knowledgeBase, record) => (
        <>
          <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: 'more' }} style={{ ...styleBreakline }}>
            {String(record.knowledgeBase || '').toString()}
          </Paragraph>
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record, index) => (
        <>
          <Switch
            defaultChecked
            value={status === 1}
            onChange={async (e) => {
              console.log(`change `, e);
              let list: any = [...tagDataItems];
              list[index]['status'] = e === true ? 1 : 0;
              setLoading(true);
              try {
                await updateTagApi({ id: record.id, payload: { status: list[index]['status'] } });
                setTagDataItems(list);
                message.success(`Update success`);
              } catch (error) {
                console.error(error);
              }
              setLoading(false);
            }}
          />
          {/* <Tag color={status == 1 ? 'success' : 'default'} key={status}>
            {status == 1 ? 'Active' : 'Inactive'}
          </Tag> */}
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        // let feedbackStatus = record.feedbackStatus;
        // let isShowApprove = [FeedbackStatusEnum.request].includes(feedbackStatus);
        // let isShowReject = [FeedbackStatusEnum.request].includes(feedbackStatus);
        // let isShowEdit = [FeedbackStatusEnum.approve, FeedbackStatusEnum.request].includes(feedbackStatus);

        return (
          <Space size="middle">
            <Tooltip title={'Edit'}>
              <EditOutlined
                onClick={(e) => {
                  setShowModalEdit(true);
                  setCurrentTagData(record);
                }}
                style={{ color: 'orange' }}
              />
            </Tooltip>

            <Tooltip title={'Delete'}>
              <Popconfirm
                title="Delete"
                description="Are you sure to Delete?"
                onConfirm={(e) => {
                  deleteTagHandler({
                    ...record,
                  });
                }}
                onCancel={(e) => {}}
                okText="Yes"
                cancelText="No"
              >
                <DeleteFilled style={{ color: 'red' }} />
              </Popconfirm>
            </Tooltip>
          </Space>
        );
      },
    },
  ];
  const loadAllBotItems = async () => {
    const setBotDataCustom = (data: any) => {
      setBotData(data);
      dispatch(setCurrentBotReduxAction(data));
    };
    setLoading(true);
    const [result] = await Promise.all([getAllBotApi()]);
    let allBotItems = result.items;
    setBotItems(allBotItems as any);
    if (!botData || !botData.id) {
      setBotDataCustom(allBotItems[0]);
    } else if (botData) {
      if (botData?.id !== appState.currentBotInstance?.id) {
        setBotDataCustom(appState.currentBotInstance);
      }
    }
    setLoading(false);
  };
  const loadTagsData = async (params: any = null) => {
    const { page: pageParam, limit: limitParam, keyword, status } = params || {};
    setLoading(true);
    const [result] = await Promise.all([
      tagListApi({
        botId: botData?.id,
        q: keyword === '' ? '' : keyword || searchValue,
        page: pageParam || page,
        limit: limitParam || limit,
        status: status || '',
      }),
    ]);
    let total = result.total;
    let items = result.items;
    setTotal(total);
    setTagDataItems(
      items.map((r: any) => {
        return {
          ...r,
          originText: r.text,
        };
      })
    );
    setLoading(false);
  };
  useEffect(() => {
    loadAllBotItems();
    loadTagsData({});
  }, []);

  const submitEditTagHandler = async () => {
    if (loading) return;
    setLoading(true);
    try {
      if (showModalAdd) {
        await addTagApi({
          payload: {
            ...currentTagData,
          },
        });
      } else {
        await updateTagApi({
          id: currentTagData?.id,
          payload: {
            ...currentTagData,
          },
        });
      }

      await loadTagsData();
      setShowModalEdit(false);
      setShowModalAdd(false);
      message.success(showModalAdd ? 'Add tag success' : `Update success`);
    } catch (error: any) {
      console.error(error);
      message.error(JSON.stringify(error));
    }
    setLoading(false);
  };
  const deleteTagHandler = async (params: any = null) => {
    if (loading) return;
    setLoading(true);

    try {
      await deleteTagApi({
        id: params?.id || currentTagData?.id,
      });
      message.success(`Delete success`);
      await loadTagsData();
      setShowModalEdit(false);
    } catch (error: any) {
      console.error(error);
      message.error(JSON.stringify(error));
    }

    setLoading(false);
  };

  return (
    <div className="list-bot-instance">
      <Card
        title={
          <Row>
            <Col span={12}>Tag</Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button
                color="primary"
                type="primary"
                onClick={(e) => {
                  setShowModalAdd(true);
                  setCurrentTagData({
                    tagType: 'feedback',
                  });
                }}
              >
                <PlusCircleOutlined /> New
              </Button>
            </Col>
          </Row>
        }
      >
        {loading && <LoadingComponent />}

        <div className="list">
          <Table
            columns={columns}
            dataSource={tagDataItems.map((r: any, idx: number) => {
              return {
                ...r,
                key: r.idx + String(idx),
              };
            })}
            pagination={false}
            style={{ marginBottom: '15px' }}
          />
          <Pagination
            current={page}
            pageSize={limit}
            total={total}
            onChange={(pageCb, pageSizeCb) => {
              console.log(`change page`, pageCb, pageSizeCb);
              if (limit !== pageSizeCb) {
                setLimit(pageSizeCb);
              }
              setPage(pageCb);
              loadTagsData({ page: pageCb, limit: pageSizeCb || limit });
            }}
          />
        </div>
      </Card>

      {(showModalEdit || showModalAdd) && (
        <Modal
          open={true}
          title={showModalAdd ? 'Add' : 'Edit'}
          onOk={(e) => {
            // setShowModalEdit(false);
            submitEditTagHandler();
          }}
          onCancel={(e) => {
            setShowModalEdit(false);
            setShowModalAdd(false);
          }}
          width={'90%'}
        >
          <Divider />
          <div>
            {[{ key: 'code', disabled: showModalAdd ? false : true }, { key: 'name', disabled: false }, { key: 'tagType' }, { key: 'knowledgeBase' }].map((keyObj: any, idx) => {
              return (
                <Row key={idx} style={{ marginBottom: '15px' }}>
                  <Col span={24}>
                    <Typography.Text>{keyObj['title'] || keyToTitle(keyObj.key)}</Typography.Text>
                  </Col>
                  <Col span={24}>
                    {keyObj.key == 'tagType' && currentTagData && (
                      <Select
                        defaultValue={currentTagData[keyObj.key]}
                        style={{ width: '100%' }}
                        onChange={(value: string) => {
                          console.log(`selected ${value}`);
                          setCurrentTagData({
                            ...currentTagData,
                            [keyObj.key]: value,
                          });
                        }}
                        options={[
                          { value: 'system', label: 'system' },
                          { value: 'feedback', label: 'feedback' },
                          { value: 'topic', label: 'topic' },
                          { value: 'none', label: 'none' },
                        ]}
                      />
                    )}
                    {keyObj.key == 'knowledgeBase' && currentTagData && (
                      <ReactQuill
                        ref={reactEditor}
                        theme="snow"
                        defaultValue={
                          {
                            ops: [
                              {
                                insert: String(currentTagData.knowledgeBase || '').toString(),
                              },
                            ],
                          } as DeltaStatic
                        }
                        onChange={(v: string, delta: DeltaStatic, source: any, editor: any) => {
                          setCurrentTagData({
                            ...currentTagData,
                            knowledgeBase: editor?.getText() || v,
                          });
                        }}
                      />
                    )}
                    {keyObj.key !== 'knowledgeBase' && keyObj.key !== 'tagType' && (
                      <Input
                        disabled={keyObj.disabled}
                        onChange={(e) => {
                          setCurrentTagData({
                            ...currentTagData,
                            [keyObj.key]: e.target.value,
                          });
                        }}
                        className="form-control"
                        value={currentTagData?.[keyObj.key]}
                      />
                    )}
                  </Col>
                </Row>
              );
            })}
          </div>
          <Divider />
        </Modal>
      )}
    </div>
  );
};

export default TagsPage;
