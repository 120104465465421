/* eslint-disable */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { getUserInfo, loginByUsernamePassword } from '../apis/authApi';
import { TOKEN_AUTH_KEY, USER_AUTH_KEY } from '../consts/constApp';
import { getAuthToken, getAuthUser, initAuthStateRedux, removeLocalStorageAndCookies, setLocalStorageAndCookies } from '../utils/authHelper';
import Cookies from 'js-cookie';

export interface AuthState {
  token?: string;
  user?: any;
  loading?: boolean;
  error?: any;
}
const initStateFromCookies = initAuthStateRedux();
const initialState: AuthState = {
  token: initStateFromCookies.token,
  user: initStateFromCookies.user,
  loading: false,
  error: null,
};

export const loginByUserPassThunk = createAsyncThunk(
  'auth/loginByUserPass', //ten action
  async ({ username, password }: any, { rejectWithValue }) => {
    console.log(`submit login`, { username, password });
    try {
      const response = await loginByUsernamePassword({ username, password });
      console.log(`login success`, response);
      return response;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const getUserInfoThunkAction = createAsyncThunk(
  'auth/getUserInfoThunk', //ten action
  async (params: any = {}, { rejectWithValue }) => {
    let token = params.token;
    try {
      const response = await getUserInfo({ token });
      // console.log(`getUserInfoThunkAction success`, response);
      return { token, user: response };
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,

  // Các action bình thường (sync action)
  reducers: {
    setUserProfile: (state, action: PayloadAction<any>) => {
      // console.log(`login success`, action);
      state.user = action.payload;
      state.loading = false;
      setLocalStorageAndCookies({ user: action.payload });
    },
    loginSuccess: (state, action: PayloadAction<any>) => {
      // console.log(`login success`, action);
      state.token = action.payload?.token;
      state.user = action.payload?.user;
      state.loading = false;
    },
    loginError: (state, action: PayloadAction<any>) => {
      state.token = '';
      state.user = null;
      state.loading = false;
      state.error = action.payload;
    },
    logout: (state) => {
      state.token = '';
      state.user = null;
      state.loading = false;
      // localStorage.removeItem(TOKEN_AUTH_KEY);
      // localStorage.removeItem(USER_AUTH_KEY);
      removeLocalStorageAndCookies();
    },
    setAuthErrorAction: (state, action: PayloadAction<any>) => {
      state.error = action.payload || null;
    },
  },

  // action async - Code logic xử lý async action
  extraReducers: (builder) => {
    builder
      .addCase(loginByUserPassThunk.pending, (state) => {
        //// Bắt đầu thực hiện action fetchCountFromApi (Promise pending)
        state.loading = true;
        state.error = null;
      })
      .addCase(loginByUserPassThunk.fulfilled, (state, action) => {
        //// Khi thực hiện action fetchCountFromApi (Promise fulfilled)
        console.log(`login success action=`, action);
        let { token, user } = (action as any).payload;
        state.loading = false;
        state.user = user;
        state.token = token;
        state.error = null;
        setLocalStorageAndCookies({ user, token });
      })
      .addCase(loginByUserPassThunk.rejected, (state, action) => {
        //// Khi thực hiện action fetchCountFromApi (Promise rejected)
        state.loading = false;
        state.user = null;
        state.token = '';
        state.error = action.payload;
        console.log('loginByUserPassThunk.rejected', action);
      });

    builder
      .addCase(getUserInfoThunkAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUserInfoThunkAction.fulfilled, (state, action) => {
        state.loading = false;
        let { token, user } = (action as any).payload;

        if (token) {
          state.token = token;
          setLocalStorageAndCookies({ token });
        }
        if (user) {
          state.user = user;
          setLocalStorageAndCookies({ user });
        }
      })
      .addCase(getUserInfoThunkAction.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        state.token = '';
        state.error = action.payload;
        console.error('Error getUserInfoThunkAction.rejected', action);
      });
  },
});

export const { loginSuccess, loginError, logout, setAuthErrorAction, setUserProfile } = authSlice.actions;
// export const selectCount = (state: RootState) => state.counter.value;
export const selectAuthState = (state: RootState) => state.auth;
export default authSlice.reducer;
