/* eslint-disable */
import { ArrowLeftOutlined, ArrowRightOutlined, CheckCircleOutlined, DownOutlined, HeatMapOutlined, InfoCircleFilled, MessageFilled, ProfileFilled, SettingFilled } from '@ant-design/icons';
import { Spin, Dropdown, Space, Typography, Steps, Button, message, Form, Card, Row, Col, Select, Switch, Input, Divider, StepProps } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectAppState, setCurrentBotReduxAction } from '../../reduxs/appSlice';
import { LoadingComponent } from '../loading';
import { keyToTitle, timeout } from '../../utils/appHelper';
import { LANGUAGES, RAG_STRATEGY } from '../../consts/constApp';
import { BotDataType } from '../../types/appTypes';
import { creteBotApi, getAllBotApi, getProviderApi } from '../../apis/appApi';
import BotInstanceProfilePage from '../../pages/botInstanceProfilePage';
import BotInstanceLLMPage from '../../pages/botInstanceLLMPage';
import BotInstanceWidgetPage from '../../pages/botInstanceWidgetPage';
const defaultBot = {
  //profile
  name: '',
  description: '',
  status: 1,
  avatar: '',
  lang: 'English',

  //LLM
  chatModel: {
    modelType: 'chat',
  },
  embedModel: {
    modelType: 'embedding',
  },
  text2SpeechModel: {
    modelType: 't2s',
  },

  ragStrategy: 'naive',
  llTemperature: '0.9',
};
const STEP_1_Profile = 'Profile';
const STEP_2_LLMKey = 'LLM';
const STEP_3_Widget = 'Widget';
const BotCreateComponent = ({ callback }: any) => {
  const dispatch = useAppDispatch();
  const appState = useAppSelector(selectAppState);
  const [loading, setLoading] = useState(false);
  const [providers, setProviders] = useState<any>([]);
  const [currentProvider, setCurrentProvider] = useState<any>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [botData, setBotData] = useState({
    ...(defaultBot as any),
  } as BotDataType);
  const steps: any[] = [
    {
      title: 'Profile',
      key: STEP_1_Profile,
      content: null,
      // subTitle: 'Setting information',
      description: 'Setting information',
      // icon: <HeatMapOutlined />,
      icon: (
        <InfoCircleFilled
          onClick={(e) => {
            console.log(`click icon profile`, e);
          }}
        />
      ),
      onClick: (e: any) => {
        console.log(`click icon profile`, e);
      },
    },
    {
      title: 'LLM',
      key: STEP_2_LLMKey,
      description: 'LLM setting',
      icon: (
        <HeatMapOutlined
          onClick={(e) => {
            console.log(`click icon HeatMapOutlined`, e);
          }}
        />
      ),
      onClick: (e: any) => {
        console.log(`click icon HeatMapOutlined`, e);
      },
    },
    {
      title: 'Widget',
      key: STEP_3_Widget,
      content: 'Last-content',
      description: 'Widget setting',
      // icon: <HeatMapOutlined />,
      icon: (
        <MessageFilled
          onClick={(e) => {
            console.log(`click icon MessageFilled`, e);
          }}
        />
      ),
      onClick: (e: any) => {
        console.log(`click icon MessageFilled`, e);
      },
    },
  ];
  const initFn: any = async () => {
    setLoading(true);
    const [providerItems] = await Promise.all([getProviderApi()]);

    setProviders(providerItems);
    setCurrentProvider(providerItems.find((r: any) => r.llmProvider == botData?.llmProvider));

    setLoading(false);
  };
  useEffect(() => {
    initFn();
  }, []);

  const onNext = async (data = null) => {
    console.log(`callback data step=`, currentStep, ` data= `, data);
    if (currentStep == 0) {
      try {
        //set botData
        let dataStep0 = {
          ...botData,
          ...(data || {}),
        };
        setBotData({
          ...dataStep0,
        });
        console.log(`data step 0 = `, dataStep0);

        //next step 2
        setCurrentStep(1);
      } catch (error) {
        console.error(error);
        return;
      }
    } else if (currentStep == 1) {
      try {
        let dataStep1 = {
          ...botData,
          ...(data || {}),
        };
        setBotData({
          ...dataStep1,
        });
        console.log(`data step 1 = `, dataStep1);
        setBotData({
          ...dataStep1,
        });
        setCurrentStep(2);
      } catch (error) {
        console.error(error);
        return;
      }
    } else if (currentStep == 2) {
      let dataStep2 = {
        ...botData,
        ...(data || {}),
      };
      setBotData({
        ...dataStep2,
      });
      console.log(`data step 2 = `, dataStep2);
      setLoading(true);
      try {
        let createRes = await creteBotApi({
          payload: dataStep2,
        });

        console.log(`dataStep2 createRes `, createRes);
        message.success(`Create bot success`);
        dispatch(setCurrentBotReduxAction(createRes));
        setCurrentStep(0);
        setBotData(defaultBot as any);
        if (callback) callback();
      } catch (error) {
        console.error(error);
        message.error(JSON.stringify(error));
      }
      setLoading(false);
    }
  };

  const onPrev = () => {
    setCurrentStep(currentStep - 1);
  };
  return (
    <>
      <Steps
        current={currentStep}
        items={steps.map((step) => {
          return {
            // className?: string;
            description: step.description || null,
            icon: step.icon || null,
            onClick: step.onClick || null,
            // status?: 'wait' | 'process' | 'finish' | 'error';
            // disabled?: boolean;
            title: step.title,
            subTitle: step.subTitle || null,
            style: {},
            key: step.key,
          };
        })}
        onChange={(curr) => {
          console.log(`current step`, curr);
          setCurrentStep(curr);
        }}
      />

      {currentStep == 0 && <BotInstanceProfilePage createdData={botData} isCreateNew={true} onNext={onNext} />}
      {currentStep == 1 && <BotInstanceLLMPage createdData={botData} isCreateNew={true} onNext={onNext} onPrev={onPrev} />}
      {currentStep == 2 && <BotInstanceWidgetPage createdData={botData} isCreateNew={true} onNext={onNext} onPrev={onPrev} />}
      {/* <div>{steps[currentStep].content}</div> */}

      {/* 
      <Divider></Divider>
      <div style={{ marginTop: '5px', marginBottom: '15px' }}>
        {currentStep > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
            <ArrowLeftOutlined /> Previous
          </Button>
        )}
        {currentStep < steps?.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next <ArrowRightOutlined />
          </Button>
        )}
        {currentStep === steps?.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            <CheckCircleOutlined /> Done
          </Button>
        )}
      </div> */}
    </>
  );
};
export default BotCreateComponent;
