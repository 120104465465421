/* eslint-disable */
import { Button, Result } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

const enum ResultType {
  Forbidden = 403,
  NotFound = 404,
}

const ResultInfo = {
  403: '403 ',
  404: '404 ',
};

type ResultCodes = keyof typeof ResultInfo;

function ResultPage(props: { resultCode: ResultCodes }) {
  const { resultCode } = props;
  const resultInfo = ResultInfo[resultCode];
  return <Result title={resultCode} subTitle={resultInfo} extra={<Link to="/">Home</Link>} />;
}

// const NotFoundPage = () => <ResultPage resultCode={ResultType.NotFound} />;
const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button
          type="primary"
          onClick={(e) => {
            navigate('/');
          }}
        >
          Back Home
        </Button>
      }
    />
  );
};

// export const NotAuth = () => <ResultPage resultCode={ResultType.Forbidden} />
export default NotFoundPage;
