import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { fetchCount } from '../apis/counterApi';

export interface CounterState {
  value: number;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: CounterState = {
  value: 0,
  status: 'idle',
};

export const fetchCountFromApi = createAsyncThunk(
  'counter/fetchCount', //ten action
  async (params: any = {}) => {
    //request call
    const response = await fetchCount(params);
    return response;
  }
);

export const counterSlice = createSlice({
  name: 'counter',
  initialState,

  // Các action bình thường (sync action)
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },
  },

  // action async - Code logic xử lý async action
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountFromApi.pending, (state) => {
        //// Bắt đầu thực hiện action fetchCountFromApi (Promise pending)
        state.status = 'loading';
      })
      .addCase(fetchCountFromApi.fulfilled, (state, action) => {
        //// Khi thực hiện action fetchCountFromApi (Promise fulfilled)
        state.status = 'idle';
        state.value += (action as any).payload;
      })
      .addCase(fetchCountFromApi.rejected, (state) => {
        //// Khi thực hiện action fetchCountFromApi (Promise rejected)
        state.status = 'failed';
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// then use like
// <button className={styles.button} onClick={() => dispatch(incrementIfOdd(incrementValue))}>
//   Add If Odd
// </button>
export const incrementIfOdd =
  (amount: number): AppThunk =>
  (dispatch, getState) => {
    const currentValue = selectCount(getState());
    if (currentValue % 2 === 1) {
      dispatch(incrementByAmount(amount));
    }
  };

export const { increment, decrement, incrementByAmount } = counterSlice.actions;
export const selectCount = (state: RootState) => state.counter.value;
export default counterSlice.reducer;
