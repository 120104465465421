export default {
  translation: {
    appName: 'ChatAI Admin',
    appDescription: 'Chào mừng đến với chatAI system',
    'Welcome to React': 'Xin chao react',
    login: 'Đăng nhập',
    email: 'Email',
    username: 'Tên đăng nhập',
    password: 'Mật khẩu',
    phone: 'Số điện thoại',
    or: 'hoặc',
    remember: 'Ghi nhớ',
    forgotPassword: 'Quên mật khẩu',
    message: {
      Selectlanguage: 'Ngôn ngữ',
    },
  },
};
