import { useState, CSSProperties, useEffect } from 'react';
import { useNavigate, useLoaderData, Navigate, useSearchParams, Link } from 'react-router-dom';
import { Button, Card, Divider, Dropdown, DropDownProps, Form, Input, message, Result, Select, Slider, Space, Switch, Tabs, Tooltip } from 'antd';
import {
  AlipayOutlined,
  AppleOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
  DownOutlined,
  FacebookOutlined,
  GoogleOutlined,
  LockOutlined,
  MobileOutlined,
  TaobaoOutlined,
  UserOutlined,
  WeiboOutlined,
} from '@ant-design/icons';
import { LoginForm, ProFormCaptcha, ProFormCheckbox, ProFormText } from '@ant-design/pro-components';
import { store, useAppDispatch, useAppSelector } from '../store';
import { selectAuthState, loginByUserPassThunk, getUserInfoThunkAction } from '../reduxs/authSlice';
import { selectLanuageState, selectThemeState } from '../reduxs/themeSlice';
import { useTranslation } from 'react-i18next';
import { loginGoogleApi } from '../apis/authApi';
import Loading, { LoadingComponent } from '../components/loading';
import Paragraph from 'antd/lib/skeleton/Paragraph';
import { GPT_MODEL_3_5, GPT_MODEL_3_5_TURBO, GPT_MODEL_4, GPT_MODEL_4_TURBO, RAG_STRATEGY } from '../consts/constApp';
import { getCurrentBot, keyToTitle } from '../utils/appHelper';
import { getAllBotApi, getAllModels, getBotByIdApi, getProviderApi, updateBotApi } from '../apis/appApi';
import { MenuProps } from 'antd/lib';
import { BotDataType } from '../types/appTypes';
import CurrentBotComponent from '../components/currentBot/currentBotComponent';
import { selectAppState, setCurrentBotReduxAction } from '../reduxs/appSlice';

const BotInstanceLLMPage = ({ isCreateNew, createdData, onNext, onPrev }: any = null) => {
  const { t, i18n } = useTranslation();
  const userInfo = useLoaderData();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuthState);
  const appState = useAppSelector(selectAppState);
  const themeState = useAppSelector(selectThemeState);
  const lang = useAppSelector(selectLanuageState);
  const [searchParams, setSearchParams] = useSearchParams();
  const [botItems, setBotItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [providers, setProviders] = useState<any>([]);
  const [allModels, setAllModels] = useState<any>([]);
  const [currentProvider, setCurrentProvider] = useState<any>(null);
  const [botData, setBotData]: [BotDataType, any] = useState(isCreateNew && createdData ? createdData : (null as any));

  const setBotDataCustom = (data: any) => {
    if (data) {
      setBotData(data);
      for (let k in data) {
        form.setFieldValue(k, data[k]);
      }
    }
  };
  const initFn: any = async () => {
    setLoading(true);
    const [providerItems, result, allmodelsItems, botInfo] = await Promise.all([
      getProviderApi(),
      getAllBotApi(),
      getAllModels({ status: 1 }),
      appState.currentBotInstance?.id ? getBotByIdApi({ id: appState.currentBotInstance?.id }) : null,
    ]);
    let allBotItems = result.items;
    let allActiveModels = allmodelsItems.filter((r: any) => r.status == 1);
    setAllModels(allActiveModels);
    setProviders(providerItems);
    setCurrentProvider(providerItems.find((r: any) => r.llmProvider == botData?.llmProvider));
    setBotItems(allBotItems as any);
    if (isCreateNew) {
      setBotDataCustom(createdData);
    } else if (!botInfo) {
      setBotDataCustom(allBotItems[0]);
    } else if (botInfo) {
      // console.log(`has redux state `,appState.currentBotInstance, botInfo);
      setBotDataCustom(botInfo);
    }
    setLoading(false);
  };
  useEffect(() => {
    initFn();
  }, [appState.currentBotInstance, isCreateNew, createdData]);

  const [form] = Form.useForm();
  const layout = {
    labelCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } },
  };
  const tailLayout = {
    wrapperCol: { xs: { span: 24 }, sm: { span: 24, offset: 0 }, md: { span: 24, offset: 0 }, lg: { span: 24, offset: 0 } },
  };

  const onFinish = async (values: any) => {
    if (isCreateNew) {
      setLoading(true);
      let updatePram: any = {
        ...botData,
      };
      ['text2SpeechModelId', 'embedModelId', 'chatModelId'].map((k: any) => {
        updatePram[k] = updatePram[k] || values[k];
      });
      onNext(updatePram);
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      let updatePram: any = {
        ...botData,
      };
      ['text2SpeechModelId', 'embedModelId', 'chatModelId'].map((k: any) => {
        updatePram[k] = updatePram[k] || values[k];
        if (!updatePram[k]) {
          let objKey = String(k).replace('Id', '');
          if (objKey && updatePram[objKey]?.id) {
            updatePram[k] = updatePram[objKey]?.id;
          }
        }
      });
      let dataUpdate = await updateBotApi({
        id: botData?.id,
        payload: {
          ...updatePram,
        },
      });
      message.success(`Update bot success`);
      dispatch(setCurrentBotReduxAction({ ...dataUpdate, id: botData?.id }));
    } catch (error) {
      console.error(error);
      message.error(JSON.stringify(error));
    }
    setLoading(false);
  };

  const onReset = () => {
    form.resetFields();
    if (initFn) initFn();
  };

  if (!botData) return null;
  return (
    <Form
      {...layout}
      // layout='horizontal'
      layout="vertical"
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      title="Bot Setting"
      // style={{ maxWidth: 600 }}
      className="form-edit-bot-instance bot-instance-llm"
    >
      <Card
        // title="Chat GPT"
        style={{}}
        title={isCreateNew ? null : <CurrentBotComponent allBotItems={botItems} />}
      >
        <div
          style={{
            maxWidth: '60%',
            margin: '0 auto',
          }}
        >
          {loading && <LoadingComponent />}
          <Divider orientation="center">LLM Setting</Divider>

          {['chatModel', 'embedModel', 'text2SpeechModel'].map((modelKey, idxModel) => {
            let setIdKey = modelKey + 'Id';
            let modelId = (botData as any)[modelKey]?.id || '';
            let modelName = (botData as any)[modelKey]?.name || '';
            let modelType = (botData as any)[modelKey]?.modelType || '';
            let filtedModels = allModels.filter((r: any) => r.modelType == modelType && modelType);
            let options = filtedModels.map((model: any) => {
              return {
                value: model?.id,
                label: model?.name || model?.description || 'Unknown name',
              };
            });
            // console.log(`modelId `,{modelId, modelName, modelType, filtedModels, options});
            return (
              <Form.Item key={setIdKey + idxModel} initialValue={modelId} name={setIdKey} label={keyToTitle(modelKey)} rules={[{ required: false }]} tooltip={'Select ' + keyToTitle(modelKey)}>
                <Select
                  disabled={loading}
                  value={modelId}
                  onChange={(e) => {
                    let id = e?.value || e;
                    setBotData({ ...botData, [setIdKey]: id });
                    form.setFieldValue(setIdKey, id);
                  }}
                  options={options}
                />
              </Form.Item>
            );
          })}
          <Form.Item
            initialValue={botData?.llTemperature}
            key={'llTemperature'}
            className="form-item-label-has-subtitle"
            name={'llTemperature'}
            label={
              <>
                <span className="title">{keyToTitle('llTemperature')}</span>
                <span className="subtitle" style={{ left: 0 }}>
                  {keyToTitle('llTemperature')}
                </span>
              </>
            }
            rules={[{ required: false }]}
          >
            <Slider
              disabled={loading}
              marks={{
                0: 'Precise',
                1: 'Balance',
                2: 'Creative',
              }}
              min={0}
              max={2}
              step={0.2}
              defaultValue={(botData?.llTemperature as any) || 1}
              onChangeComplete={(v) => {
                // console.log(`change slider`, v);
                setBotData({ ...botData, llTemperature: v });
              }}
            />
          </Form.Item>

          <Form.Item initialValue={botData?.prompt || ''} key={'prompt'} name={'prompt'} label={keyToTitle('systemPrompt')} rules={[{ required: false }]}>
            <Input.TextArea
              rows={3}
              placeholder={'Input system prompt '}
              onChange={(e) => {
                setBotData({ ...botData, prompt: e.target.value });
              }}
              value={botData?.prompt}
              disabled={loading}
            />
          </Form.Item>

          <Form.Item
            initialValue={botData?.ragPrompt || ''}
            key={'ragPrompt'}
            // className="form-item-label-has-subtitle"
            name={'ragPrompt'}
            label={keyToTitle('ragPrompt')}
            // label={
            //   <>
            //     <span className="title">{keyToTitle('prompt')}</span>
            //     <span className="subtitle" style={{ left: 0 }}>
            //       {keyToTitle('prompt')}
            //     </span>
            //   </>
            // }
            rules={[{ required: false }]}
          >
            <Input.TextArea
              rows={3}
              placeholder={'Input prompt '}
              onChange={(e) => {
                setBotData({ ...botData, ragPrompt: e.target.value });
              }}
              value={botData?.ragPrompt}
              disabled={loading}
            />
          </Form.Item>
          <Form.Item {...tailLayout} style={{ marginTop: '15px' }} label=" ">
            <Space>
              {isCreateNew ? (
                <>
                  <Button style={{ margin: '0 8px' }} onClick={() => onPrev()}>
                    <ArrowLeftOutlined /> Previous
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Next <ArrowRightOutlined />
                  </Button>
                </>
              ) : (
                <>
                  <Button type="primary" htmlType="submit" disabled={loading}>
                    Submit
                  </Button>
                  <Button htmlType="button" onClick={onReset} disabled={loading}>
                    Cancel
                  </Button>
                </>
              )}
            </Space>
          </Form.Item>
        </div>
      </Card>
    </Form>
  );
};

export default BotInstanceLLMPage;
