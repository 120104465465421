import { useState, CSSProperties, useEffect } from 'react';
import { useNavigate, useLoaderData, Navigate } from 'react-router-dom';
import { Alert, Button, Col, Divider, message, notification, Row, Space, Tabs } from 'antd';
import { AlipayOutlined, AppleOutlined, FacebookOutlined, GoogleCircleFilled, GoogleOutlined, LockOutlined, MobileOutlined, TaobaoOutlined, UserOutlined, WeiboOutlined } from '@ant-design/icons';
import { LoginForm, ProFormCaptcha, ProFormCheckbox, ProFormText } from '@ant-design/pro-components';
import { store, useAppDispatch, useAppSelector } from '../store';
import { selectAuthState, loginByUserPassThunk, setAuthErrorAction, AuthState } from '../reduxs/authSlice';
import { selectLanuageState, selectThemeState } from '../reduxs/themeSlice';
import { useTranslation } from 'react-i18next';
import { loginGoogleApi } from '../apis/authApi';
import Loading, { LoadingComponent } from '../components/loading';
import { API_URL, APP_URL } from '../consts/constApp';

// import { login } from '@/routes/auth'
// import { type LoginInfo } from '@/apis'

type LoginType = 'phone' | 'account';

const iconCircleStyles: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: 40,
  width: 40,
  border: '1px solid #D4D8DD',
  borderRadius: '50%',
};

const iconStyles: CSSProperties = {
  color: 'rgba(0, 0, 0, 0.2)',
  fontSize: '18px',
  verticalAlign: 'middle',
  cursor: 'pointer',
};
const getErrorMessage = (err: any) => {
  let mess = JSON.stringify(err);
  if (err?.status && err?.error) {
    return err?.status + ' ' + err?.error;
  }
  return mess;
};

const Login = () => {
  const { t, i18n } = useTranslation();
  const [loginType, setLoginType] = useState<LoginType>('account');
  const userInfo = useLoaderData();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authState: AuthState = useAppSelector(selectAuthState);
  const themeState = useAppSelector(selectThemeState);
  const lang = useAppSelector(selectLanuageState);
  console.log(`authState`, authState);
  useEffect(() => {
    if (authState.error) {
      notification.error({
        message: 'Login fail',
        description: getErrorMessage(authState.error?.message || authState.error),
        duration: 3,
        onClose: () => {
          console.log(`trigger close`);
          dispatch(setAuthErrorAction(null));
        },
      });
    }
  }, [authState.error]);
  if (authState.token && authState.user) {
    notification.success({
      message: 'Login successfully',
      // description: '',
      duration: 3,
      onClose: () => {
        // console.log(`trigger close`);
        // dispatch(setAuthErrorAction(null))
      },
    });
    // message.success(`Login successfully`);
    return <Navigate replace to="/"></Navigate>;
  }

  const onSubmitLogin = async (values: any) => {
    console.log(values);
    dispatch(
      loginByUserPassThunk({
        username: values.username,
        password: values.password,
      })
    );
    // message.success(`Login success`);
    // navigate('/', { replace: true });
  };
  const loginAzureApi = (e: any) => {
    let url = API_URL + `/auth/saml/sso?redirectUrl=${encodeURIComponent(APP_URL as any)}`;
    console.log(`login azure go to url`, url);
    window.location.href = url;
  };
  // console.log({lang});
  return (
    <div className="login-page">
      <LoginForm
        onFinish={onSubmitLogin}
        title={t('appName')}
        subTitle={t('appDescription')}
        lang={lang}
        loading={authState.loading}
        disabled={authState.loading}
        // actions={
        //   <div
        //     style={{
        //       display: 'flex',
        //       justifyContent: 'center',
        //       alignItems: 'center',
        //       flexDirection: 'column',
        //     }}
        //   >
        //     <Divider plain>
        //       <span style={{ color: '#CCC', fontWeight: 'normal', fontSize: 14 }}>{t('or')}</span>
        //     </Divider>
        //     <Space align="center" size={24}>
        //       {/* <div style={iconCircleStyles}>
        //         <FacebookOutlined style={{ ...iconStyles, color: themeState.colorPrimary }} />
        //       </div> */}
        //       <div style={iconCircleStyles}>
        //         <GoogleOutlined
        //           onClick={(e) => {
        //             console.log(`click login gg`, e);
        //             loginGoogleApi();
        //           }}
        //           style={{ ...iconStyles, color: themeState.colorPrimary }}
        //         />
        //       </div>
        //       {/* <div style={iconCircleStyles}>
        //         <AppleOutlined style={{ ...iconStyles, color: themeState.colorSuccess }} />
        //       </div> */}
        //     </Space>
        //   </div>
        // }
      >
        <Button
          onClick={(e) => {
            loginAzureApi(e);
          }}
          className="btn-social btn-login-azure"
          icon={<img className="icon-azure" src={'/images/azure.svg'} />}
        >
          Sign in with Azure
        </Button>

        {/*Login with gg  */}
        {/* <Button
          className="btn-social btn-login-google"
          icon={
            <GoogleOutlined
              onClick={(e) => {
                console.log(`click login gg`, e);
                loginGoogleApi();
              }}
              style={{ ...iconStyles, color: themeState.colorPrimary }}
            />
          }
        >
          Sign in with Google
        </Button> */}

        <Divider plain>
          <span style={{ color: '#CCC', fontWeight: 'normal', fontSize: 14 }}>{t('or')}</span>
        </Divider>
        {/* <Tabs centered activeKey={loginType} onChange={(activeKey) => setLoginType(activeKey as LoginType)}>
          <Tabs.TabPane key="account" tab={t('username')} />
          <Tabs.TabPane key="phone" tab={t('phone')} />
        </Tabs> */}
        <span className="label-signin-with-email">Sign in with Email</span>
        {
          // authState.error && (
          //   <Alert
          //     style={{ marginBottom: '15px' }}
          //     // message="Error"
          //     type="error"
          //     showIcon
          //     message={getErrorMessage(authState.error)}
          //   />
          // )
        }
        {
          // authState.loading && <LoadingComponent />
        }

        {loginType === 'account' && (
          <>
            <ProFormText
              name="username"
              fieldProps={{
                size: 'large',
                prefix: <UserOutlined className="prefixIcon" />,
              }}
              placeholder={t('username')}
              rules={[{ required: true }]}
            />
            <ProFormText.Password
              name="password"
              fieldProps={{
                size: 'large',
                prefix: <LockOutlined className="prefixIcon" />,
              }}
              placeholder="******"
              rules={[{ required: true }]}
            />
          </>
        )}
        {loginType === 'phone' && (
          <>
            <ProFormText
              fieldProps={{
                size: 'large',
                prefix: <MobileOutlined className="prefixIcon" />,
              }}
              name="mobile"
              placeholder={t('phone')}
              rules={[{ required: true }, { pattern: /^1\d{10}$/ }]}
            />
            <ProFormCaptcha
              fieldProps={{
                size: 'large',
                prefix: <LockOutlined className="prefixIcon" />,
              }}
              captchaProps={{
                size: 'large',
              }}
              placeholder="******"
              captchaTextRender={(timing: boolean, count: number) => {
                if (timing) return `${count} `;
                return 'Otp';
              }}
              name="captcha"
              rules={[{ required: true }]}
              onGetCaptcha={async () => {
                message.success('Get captcha success');
              }}
            />
          </>
        )}
        <div style={{ marginBlockEnd: 24 }}>
          <ProFormCheckbox noStyle name="autoLogin">
            {t('remember')}
          </ProFormCheckbox>
          <a style={{ float: 'right' }}>{t('forgotPassword')}</a>
        </div>
      </LoginForm>
    </div>
  );
};

export default Login;
