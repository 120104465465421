import { Breadcrumb, Row, Space, Button, Dropdown, MenuProps, Modal, Radio, Image, Typography, Avatar } from 'antd';
import { Link, useLocation, useNavigate, Navigate, useLoaderData } from 'react-router-dom';
import { BellOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, SearchOutlined, SettingOutlined, TranslationOutlined, UserOutlined } from '@ant-design/icons';

// import Theme from '@/features/theme/theme'

import { breadcrumbNameMap } from '../routes/routes';
import { logout, selectAuthState } from '../reduxs/authSlice';
import { useAppDispatch, useAppSelector } from '../store';
import { useState } from 'react';
import { RadioChangeEvent } from 'antd/lib';
import { changeLanguage, changeThemeKaola, changeThemeMain, changeThemeTln, selectLanuageState, selectThemeState } from '../reduxs/themeSlice';
import { LANG_EN, LANG_VN, THEME_TLN } from '../consts/constApp';
import { useTranslation } from 'react-i18next';
import usFlag from './../i18n-data/us.svg';
import vnFlag from './../i18n-data/vn.svg';
// import { logout } from '../routes/auth'
const styleIcon = {
  padding: 2.5,
  fontSize: '16px',
  cursor: 'pointer',
};
export default function HeaderLayout({ collapsed, setCollapsed }: any) {
  const { t, i18n } = useTranslation();
  const userInfo: any = useLoaderData();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const themeState = useAppSelector(selectThemeState);
  const userLogged = useAppSelector(selectAuthState);
  const lang = useAppSelector(selectLanuageState);
  const [showSelectTheme, setShowSelectTheme] = useState(false);
  const [showSelectLang, setShowSelectLang] = useState(false);

  if (!userInfo || !userLogged) {
    return <Navigate replace to="/login" />;
  }

  const pathSnippets = location.pathname.split('/').filter((i) => i);
  const breadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return {
      key: url,
      title: <Link to={url}>{breadcrumbNameMap[url]}</Link>,
    };
  });

  const handleLogout = async () => {
    // await logout()
    dispatch(logout());
    // navigate('/login', { replace: true })
  };

  const items: MenuProps['items'] = [
    {
      label: (
        <>
          <span>{userLogged?.user?.username}</span>
          <br />
          <Typography.Text type="success">Role: {userLogged?.user?.role}</Typography.Text>
        </>
      ),
      key: 'userInfo',
    },
    {
      label: (
        <Button type="link" size="small" style={{ color: 'gray' }}>
          <UserOutlined />
          <span>Profile</span>
        </Button>
      ),
      key: '0',
    },
    {
      label: (
        <Button type="link" size="small" style={{ color: 'gray' }}>
          <SettingOutlined />
          <span>Setting</span>
        </Button>
      ),
      key: '1',
    },
    { type: 'divider' },
    {
      label: (
        <Button type="link" size="small" onClick={handleLogout} style={{ color: 'gray' }}>
          <LogoutOutlined />
          <span>Logout</span>
        </Button>
      ),
      key: '3',
    },
  ];

  return (
    <Row
      style={{
        height: 48,
        padding: '0px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: 0,
        // borderBottom: '1px solid #f1f1f1',
        background: '#fff',
      }}
    >
      <Row align="middle">
        <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          style={{
            fontSize: '16px',
            width: 48,
            height: 48,
          }}
        />

        {/* <MenuFoldOutlined style={{ marginRight: '10px' }} /> */}
        <Breadcrumb items={breadcrumbItems} />
      </Row>
      <Space size={12}>
        {/* <Theme /> */}
        <SearchOutlined style={styleIcon} />
        <BellOutlined style={styleIcon} />
        <SettingOutlined
          style={styleIcon}
          title="Theme"
          onClick={(e) => {
            setShowSelectTheme(!showSelectTheme);
          }}
        />

        {!lang ? (
          <TranslationOutlined
            style={styleIcon}
            onClick={(e) => {
              setShowSelectLang(true);
            }}
          />
        ) : (
          <Image
            style={styleIcon}
            width={24}
            src={lang == LANG_EN ? usFlag : vnFlag}
            preview={false}
            onClick={(e) => {
              setShowSelectLang(true);
            }}
          />
        )}

        <Dropdown menu={{ items }} placement="bottom">
          <Row align="middle">
            <Avatar src={userLogged?.user?.avatar} style={{ width: 32, height: 32 }} />
            {/* <img src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png" style={{ width: 32, height: 32 }} /> */}
            {/* <span style={{ marginLeft: 8, color: 'gray' }}>{userInfo?.user?.username}</span> */}
          </Row>
        </Dropdown>

        <Modal
          title={'Select theme'}
          open={showSelectTheme}
          onCancel={(e) => {
            setShowSelectTheme(false);
          }}
          onOk={(e) => {
            setShowSelectTheme(false);
          }}
        >
          <Radio.Group
            options={[
              { label: 'Main', value: 'main' },
              { label: 'Kaola', value: 'kaola' },
              { label: 'TLN', value: THEME_TLN },
            ]}
            onChange={({ target: { value } }: RadioChangeEvent) => {
              console.log('radio4 checked', value);
              // setValue4(value);
              value == 'main' ? dispatch(changeThemeMain({})) : value == THEME_TLN ? dispatch(changeThemeTln({})) : dispatch(changeThemeKaola({}));
              setShowSelectTheme(false);
            }}
            value={themeState.themeName || 'main'}
            optionType="button"
            buttonStyle="solid"
          />
        </Modal>

        <Modal
          title={t('message.Selectlanguage')}
          open={showSelectLang}
          onCancel={(e) => {
            setShowSelectLang(false);
          }}
          onOk={(e) => {
            setShowSelectLang(false);
          }}
          footer={null}
        >
          <Radio.Group
            value={lang}
            onChange={({ target: { value } }: RadioChangeEvent) => {
              i18n.changeLanguage(value);
              dispatch(
                changeLanguage({
                  lang: value,
                })
              );
              setShowSelectLang(false);
            }}
          >
            <Radio value={LANG_EN} checked={lang == LANG_EN}>
              <Image width={40} src={usFlag} preview={false} />
            </Radio>
            <Radio value={LANG_VN} checked={lang == LANG_VN}>
              <Image width={40} src={vnFlag} preview={false} />
            </Radio>
          </Radio.Group>
        </Modal>
      </Space>
    </Row>
  );
}
