import { useState, CSSProperties, useEffect } from 'react';
import { useNavigate, useLoaderData, Navigate, useSearchParams, Link } from 'react-router-dom';
import { Avatar, Button, Card, Col, Divider, Dropdown, DropDownProps, Form, Input, message, Modal, Result, Row, Select, Space, Spin, Switch, Tabs, Tooltip, Upload, UploadProps } from 'antd';
import {
  AlipayOutlined,
  AppleOutlined,
  ArrowRightOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
  DownOutlined,
  FacebookOutlined,
  GoogleOutlined,
  LoadingOutlined,
  LockOutlined,
  MessageOutlined,
  MobileOutlined,
  TaobaoOutlined,
  UploadOutlined,
  UserOutlined,
  WeiboOutlined,
} from '@ant-design/icons';
import { LoginForm, ProFormCaptcha, ProFormCheckbox, ProFormText } from '@ant-design/pro-components';
import { store, useAppDispatch, useAppSelector } from '../store';
import { selectAuthState, loginByUserPassThunk, getUserInfoThunkAction } from '../reduxs/authSlice';
import { selectLanuageState, selectThemeState } from '../reduxs/themeSlice';
import { useTranslation } from 'react-i18next';
import { loginGoogleApi } from '../apis/authApi';
import Loading, { LoadingComponent } from '../components/loading';
import Paragraph from 'antd/lib/skeleton/Paragraph';
import { GPT_MODEL_3_5, GPT_MODEL_3_5_TURBO, GPT_MODEL_4, GPT_MODEL_4_TURBO, LANGUAGES, RAG_STRATEGY } from '../consts/constApp';
import { getCurrentBot, keyToTitle, setCurrentBot } from '../utils/appHelper';
import { creteBotApi, getAllBotApi, getAxiosForUploadImage, getBotByIdApi, getProviderApi, updateBotApi } from '../apis/appApi';
import { MenuProps } from 'antd/lib';
import { BotDataType } from '../types/appTypes';
import CurrentBotComponent from '../components/currentBot/currentBotComponent';
import { selectAppState, setCurrentBotReduxAction } from '../reduxs/appSlice';
import PreviewWebChatComponent from '../components/previewWebChat/previewWebChatComponent';
import { apiClient } from '../apis/axiosBase';

const BotInstanceProfilePage = ({ isCreateNew, createdData, onNext }: any = null) => {
  const { t, i18n } = useTranslation();
  const userInfo = useLoaderData();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuthState);
  const appState = useAppSelector(selectAppState);
  const themeState = useAppSelector(selectThemeState);
  const lang = useAppSelector(selectLanuageState);
  const [searchParams, setSearchParams] = useSearchParams();
  const [botItems, setBotItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [providers, setProviders] = useState<any>([]);
  const [currentProvider, setCurrentProvider] = useState<any>(null);
  const [botData, setBotData]: [BotDataType, any] = useState(isCreateNew ? createdData : null);
  const propsUploadAvatar: UploadProps = {
    onChange(info: any) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        // message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    showUploadList: false,
    customRequest: async (options: any) => {
      const { onSuccess, onError, file, onProgress } = options;
      const fmData = new FormData();
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: (event: any) => {
          // console.log((event.loaded / event.total) * 100);
          // onProgress({ percent: (event.loaded / event.total) * 100 },file);
        },
      };
      fmData.append('files', file);
      setUploading(true);
      try {
        let res: any = await getAxiosForUploadImage({
          formData: fmData,
          config,
        });
        onSuccess(file);
        let avatar = res?.data?.data?.resourceUrls?.[0];
        // console.log(`upload image success`, res);
        // console.log(`upload image success avatar`, avatar);
        // try {
        await updateBotApi({
          id: botData.id,
          payload: { avatar: avatar },
        });
        message.success(`Upload avatar success`);
        let refreshBot = {
          ...botData,
          avatar: avatar,
        };
        setBotData({
          ...refreshBot,
          avatar: avatar,
        });
        if (!isCreateNew) {
          setCurrentBot({
            ...refreshBot,
          });
          dispatch(setCurrentBotReduxAction(refreshBot));
        }
        // } catch (error) {
        //   console.error(error);
        //   message.error(JSON.stringify(error));
        // }
        // setLoading(false);
      } catch (error) {
        console.error(error);
        message.error(JSON.stringify(error));
      }
      setUploading(false);

      // getAxiosForUploadImage({
      //   formData: fmData,
      //   config,
      // })
      //   .then(async (res: any) => {
      //     onSuccess(file);
      //     let avatar = res?.data?.data?.resourceUrls?.[0];
      //     // console.log(`upload image success`, res);
      //     // console.log(`upload image success avatar`, avatar);
      //     // try {
      //       await updateBotApi({
      //         id: botData.id,
      //         payload:{avatar: avatar}
      //       });
      //       message.success(`Upload avatar success`);
      //       let refreshBot = {
      //         ...botData,
      //         avatar: avatar,
      //       };
      //       setBotData({
      //         ...refreshBot,
      //         avatar: avatar,
      //       });
      //       if (!isCreateNew) {
      //         setCurrentBot({
      //           ...refreshBot,
      //         });
      //         dispatch(setCurrentBotReduxAction(refreshBot));
      //       }
      //     // } catch (error) {
      //     //   console.error(error);
      //     //   message.error(JSON.stringify(error));
      //     // }
      //     // setLoading(false);
      //   })
      //   .catch((error: any) => {
      //     setLoading(false);
      //     // const error = new Error('Some error');
      //     // onError({event:error});
      //     console.error(error);
      //     message.error(JSON.stringify(error));
      //   });
    },
  };
  const setBotDataCustom = (data: any) => {
    setBotData(data);
    for (let k in data) {
      form.setFieldValue(k, data[k]);
    }
  };
  const initFn: any = async () => {
    setLoading(true);
    const [providerItems, result, botInfo] = await Promise.all([getProviderApi(), getAllBotApi(), appState.currentBotInstance?.id ? getBotByIdApi({ id: appState.currentBotInstance?.id }) : null]);
    let allBotItems = result.items;
    setProviders(providerItems);
    setCurrentProvider(providerItems.find((r: any) => r.llmProvider == botData?.llmProvider));
    setBotItems(allBotItems as any);
    if (isCreateNew) {
      setBotDataCustom(createdData);
    } else if (!botInfo) {
      setBotDataCustom(allBotItems[0]);
    } else if (botInfo) {
      // console.log(`has redux state `,appState.currentBotInstance, botInfo);
      setBotDataCustom(botInfo);
    }
    setLoading(false);
  };

  useEffect(() => {
    initFn();
  }, [appState.currentBotInstance, isCreateNew, createdData]);

  const [form] = Form.useForm();
  const layout = {
    labelCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } },
  };
  const tailLayout = {
    wrapperCol: { xs: { span: 24 }, sm: { span: 24, offset: 0 }, md: { span: 24, offset: 0 }, lg: { span: 24, offset: 0 } },
  };
  const onFinish = async (values: any) => {
    if (isCreateNew) {
      setLoading(true);
      // try {
      let param: any = {
        ...botData,
      };
      getFieldKeys().forEach((k) => {
        param[k] = (botData as any)[k];
      });
      onNext(param);
      setLoading(false);
      return;
    }
    const confirmFn = async () => {
      setLoading(true);
      try {
        let param: any = {};
        getFieldKeys().forEach((k) => {
          param[k] = (botData as any)[k];
        });
        await updateBotApi({
          id: botData?.id,
          payload: param,
        });

        message.success(`Update bot success`);
        let botResult = await getBotByIdApi({ id: botData?.id });
        dispatch(setCurrentBotReduxAction(botResult));
      } catch (error) {
        console.error(error);
        message.error(JSON.stringify(error));
      }
      setLoading(false);
    };
    confirmFn();
  };

  const onReset = () => {
    form.resetFields();
    if (initFn) initFn();
  };
  const getFieldKeys = () => {
    let allowKeys = [
      'name',
      'description',
      'avatar',
      'lang',
      // 'brainTags'
      'feedbackMessage',
      'feedbackInSeconds',
      'feedbackReply',
      'startBoxHint',
    ];
    return allowKeys;
  };
  const subtitleMap: any = {
    feedbackInSeconds: 'Idea time: after this time the feedback will be displayed',
    startBoxHint: 'The placeholder of input element',
  };

  if (!botData) return null;

  return (
    <Form
      {...layout}
      // layout='horizontal'
      layout="vertical"
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      title="Bot Setting"
      // style={{ maxWidth: 600 }}
      className="form-edit-bot-instance bot-instance-profile"
    >
      <Card
        // title="Chat GPT"
        style={
          {
            // maxWidth:'60%',
            // margin: '0 auto',
          }
        }
        title={
          isCreateNew ? null : (
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <CurrentBotComponent
                  allBotItems={botItems}
                  // callback={(selectBot: any) => {
                  //   setBotData(selectBot);
                  // }}
                />
              </Col>
              <Col span={12}>
                <PreviewWebChatComponent botData={botData} />
              </Col>
            </Row>
          )
        }
      >
        <div
          style={{
            maxWidth: '70%',
            margin: '0 auto',
          }}
        >
          {loading && <LoadingComponent />}
          {getFieldKeys().map((key, idx) => {
            return (
              <Form.Item
                initialValue={(botData as any)[key]}
                key={key + idx}
                className="form-item-label-has-subtitle"
                name={key}
                label={
                  <>
                    <span className="title">{keyToTitle(key)}</span>
                    <span className="subtitle" style={{ left: 0 }}>
                      {subtitleMap[key] || keyToTitle(key)}
                    </span>
                  </>
                }
                rules={[{ required: false }]}
              >
                {key == 'lang' ? (
                  <Select
                    value={botData?.lang}
                    onChange={(e) => {
                      // console.log(`select model`, e);
                      // botData.lang = e;
                      setBotData({ ...botData, lang: e });
                    }}
                    options={LANGUAGES.map((lang) => {
                      return {
                        label: lang.name,
                        value: lang.name,
                      };
                    })}
                  />
                ) : key == 'status' ? (
                  <Switch
                    loading={loading}
                    disabled={loading}
                    value={(botData as any)['status'] == 1}
                    // checked={(botData as any)['status'] == 1}
                    onChange={async (e) => {
                      // console.log(`toogle change `, botData, e);
                      // botData['status'] = e === false ? 0 : 1;
                      // setBotData(botData);
                      setBotData({ ...botData, status: e === false ? 0 : 1 });
                    }}
                  />
                ) : key == 'avatar' ? (
                  <>
                    <Space>
                      <Avatar src={(botData as any).avatar} style={{ width: '80px', height: '80px' }} />
                      <Upload {...propsUploadAvatar} className="profile-avatar-btn-upload" style={{ position: 'absolute' }}>
                        <Button disabled={uploading} icon={<UploadOutlined />}></Button>
                        {uploading && <Spin indicator={<LoadingOutlined spin />} />}
                      </Upload>
                    </Space>
                  </>
                ) : (
                  <Input
                    placeholder={keyToTitle(key)}
                    onChange={(e) => {
                      // (botData as any)[key] = e.target.value;
                      // setBotData(botData);
                      setBotData({ ...botData, [key]: e.target.value });
                    }}
                    value={(botData as any)[key as string]}
                    // defaultValue={(botData as any)[key] as string}
                    disabled={loading}
                  />
                )}
              </Form.Item>
            );
          })}

          <Form.Item {...tailLayout} style={{ marginTop: '15px' }}>
            <Space>
              {isCreateNew ? (
                <>
                  <Button type="primary" htmlType="submit">
                    Next <ArrowRightOutlined />
                  </Button>
                </>
              ) : (
                <>
                  <Button type="primary" htmlType="submit" disabled={loading}>
                    Submit
                  </Button>
                  <Button htmlType="button" onClick={onReset} disabled={loading}>
                    Cancel
                  </Button>
                </>
              )}
            </Space>
          </Form.Item>
        </div>
      </Card>
    </Form>
  );
};

export default BotInstanceProfilePage;
