/* eslint-disable */
import { useState, CSSProperties, useEffect } from 'react';
import { useNavigate, useLoaderData, Navigate, useSearchParams, Link } from 'react-router-dom';
import { Button, Divider, message, Result, Space, Tabs } from 'antd';
import { AlipayOutlined, AppleOutlined, CloseCircleOutlined, FacebookOutlined, GoogleOutlined, LockOutlined, MobileOutlined, TaobaoOutlined, UserOutlined, WeiboOutlined } from '@ant-design/icons';
import { LoginForm, ProFormCaptcha, ProFormCheckbox, ProFormText } from '@ant-design/pro-components';
import { store, useAppDispatch, useAppSelector } from '../store';
import { selectAuthState, loginByUserPassThunk, getUserInfoThunkAction, AuthState } from '../reduxs/authSlice';
import { selectLanuageState, selectThemeState } from '../reduxs/themeSlice';
import { useTranslation } from 'react-i18next';
import { loginGoogleApi } from '../apis/authApi';
import Loading from '../components/loading';
import Paragraph from 'antd/lib/skeleton/Paragraph';
let idInterval: any;
const LoginCallback = () => {
  const { t, i18n } = useTranslation();
  const userInfo = useLoaderData();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authState: AuthState = useAppSelector(selectAuthState);
  const themeState = useAppSelector(selectThemeState);
  const lang = useAppSelector(selectLanuageState);
  const [searchParams, setSearchParams] = useSearchParams();
  const tokenLoginCallback = searchParams.get('token');
  const errorCallback = searchParams.get('error');
  if (tokenLoginCallback && authState.loading == false && !authState.error) {
    if (!authState.token && !authState.user) {
      // console.log(`call login with tokenLoginCallback`, tokenLoginCallback);
      dispatch(getUserInfoThunkAction({ token: tokenLoginCallback }));
    }
  }
  useEffect(() => {
    if (authState.user) {
      message.info(`Login Azure successfully.`);
      navigate('/');
    }
  }, [authState.user]);

  if (authState.user) {
    return (
      <Result
        status="success"
        title="Successfully"
        subTitle="Login successfully, you will be redirect to Home"
        extra={[
          <Link key="1" to="/">
            Home
          </Link>,
        ]}
      >
        <div className="desc"></div>
      </Result>
    );
  }
  if (authState.error) {
    console.log('login fail error = ', authState.error);
    let subTitle = 'There are some errors when trying login. Please try again.';
    if (authState.error?.code && authState.error?.message) {
      subTitle = `${authState.error?.code} - ${authState.error?.message}`;
    } else {
      if (authState.error?.message) {
        subTitle = `${authState.error?.message}`;
      }
    }
    return (
      <Result
        status="error"
        title="Login Failed"
        subTitle={subTitle}
        extra={[
          <Link key="1" to="/">
            Home
          </Link>,
        ]}
      >
        <div className="desc">
          <div style={{ color: 'red' }}>{errorCallback}</div>
        </div>
      </Result>
    );
  }
  return <div style={{ backgroundColor: '#fff', height: '100vh' }}>{!tokenLoginCallback ? <Loading /> : authState.loading ? <Loading /> : null}</div>;
};

export default LoginCallback;
