import { API_URL } from '../consts/constApp';
import { getAuthToken } from '../utils/authHelper';
import { apiClient } from './axiosBase';
import * as _ from 'lodash';

export const loginGoogleApi = async () => {
  window.location.href = API_URL + '/auth/google';
  return;
  // try {
  //   const result = await apiClient.get("/auth/google");
  //   console.log(result);
  // } catch (error) {
  //   console.log(error);
  //   throw error;
  // }
};
export const getUserInfo = async ({ token }: any) => {
  if (!token) {
    token = getAuthToken();
  }
  // console.log(`token`, token);
  try {
    const result = await apiClient.get('/auth/user', {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    // console.log(`getUserInfo api`, result);
    return result?.data?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const loginByUsernamePassword = async ({ username, password }: any) => {
  try {
    const result = await apiClient.post(
      '/api/pub/login',
      {
        username,
        password,
      },
      {
        headers: {
          // authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(`loginByUsernamePassword api`, result);
    let token = result?.data?.data?.token;
    let user = result?.data?.data?.user;
    // console.log(`getUserInfo api`, result);
    // return result?.data?.data;
    return {
      token,
      user: {
        ..._.omit(user, ['password']),
      },
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};
