/* eslint-disable */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { getUserInfo, loginByUsernamePassword } from '../apis/authApi';
import { TOKEN_AUTH_KEY, USER_AUTH_KEY } from '../consts/constApp';
import { getAuthToken, getAuthUser } from '../utils/authHelper';
import { getCurrentBot, setCurrentBot } from '../utils/appHelper';

export interface AppState {
  currentBotInstance?: any;
  ragMode: any[];
}

const initialState: AppState = {
  currentBotInstance: getCurrentBot(),
  ragMode: [],
};

// export const loginByUserPassThunk = createAsyncThunk(
//   'auth/loginByUserPass', //ten action
//   async (params: any = {}, { rejectWithValue }) => {
//     try {
//       const response = await loginByUsernamePassword(params);
//       console.log(`login success`, response);
//       return response;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

// export const getUserInfoThunkAction = createAsyncThunk(
//   'auth/getUserInfoThunk', //ten action
//   async (params: any = {}, { rejectWithValue }) => {
//     let token = params.token;
//     try {
//       const response = await getUserInfo({ token });
//       // console.log(`getUserInfoThunkAction success`, response);
//       return { token, user: response };
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

export const appSlice = createSlice({
  name: 'appState',
  initialState,

  // Các action bình thường (sync action)
  reducers: {
    setCurrentBotReduxAction: (state, action: PayloadAction<any>) => {
      // console.log(`login success`, action);
      state.currentBotInstance = action.payload;
      setCurrentBot(action.payload);
    },
    setRagModelReduxAction: (state, action) => {
      // console.log(`set ragMode`, action.payload);
      state.ragMode = action.payload;
    },
    // loginError: (state, action: PayloadAction<any>) => {
    //   state.token = '';
    //   state.user = null;
    //   state.loading = false;
    // },
    // logout: (state) => {
    //   state.token = '';
    //   state.user = null;
    //   state.loading = false;
    //   localStorage.removeItem(TOKEN_AUTH_KEY);
    //   localStorage.removeItem(USER_AUTH_KEY);
    // },
  },

  // action async - Code logic xử lý async action
  extraReducers: (builder) => {
    // builder
    //   .addCase(loginByUserPassThunk.pending, (state) => {
    //     //// Bắt đầu thực hiện action fetchCountFromApi (Promise pending)
    //     state.loading = true;
    //   })
    //   .addCase(loginByUserPassThunk.fulfilled, (state, action) => {
    //     //// Khi thực hiện action fetchCountFromApi (Promise fulfilled)
    //     console.log(`login success action=`, action);
    //     let { token, user } = (action as any).payload;
    //     state.loading = false;
    //     state.user = user;
    //     state.token = token;
    //     localStorage.setItem(TOKEN_AUTH_KEY, token);
    //     localStorage.setItem(USER_AUTH_KEY, JSON.stringify(user));
    //   })
    //   .addCase(loginByUserPassThunk.rejected, (state, action) => {
    //     //// Khi thực hiện action fetchCountFromApi (Promise rejected)
    //     state.loading = false;
    //     state.user = null;
    //     state.token = '';
    //     state.error = action.payload;
    //     console.log('loginByUserPassThunk.rejected', action);
    //   });
  },
});

export const { setCurrentBotReduxAction, setRagModelReduxAction } = appSlice.actions;
// export const selectCount = (state: RootState) => state.counter.value;
export const selectAppState = (state: RootState) => state.app;
export default appSlice.reducer;
