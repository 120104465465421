import { DownOutlined } from '@ant-design/icons';
import { Spin, Dropdown, Space, Typography, Avatar } from 'antd';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectAppState, setCurrentBotReduxAction } from '../../reduxs/appSlice';
import { getCurrentBot, setCurrentBot } from '../../utils/appHelper';

const CurrentBotComponent = ({ allBotItems = [], callback }: any) => {
  const dispatch = useAppDispatch();
  const appState = useAppSelector(selectAppState);
  const cacheCurrBot = getCurrentBot();
  const stateCurrBot = appState.currentBotInstance;
  if (cacheCurrBot && stateCurrBot) {
    if (cacheCurrBot.id !== stateCurrBot.id) {
      dispatch(setCurrentBotReduxAction(stateCurrBot));
      setCurrentBot(stateCurrBot);
    }
  } else if (!cacheCurrBot && stateCurrBot) {
    setCurrentBot(stateCurrBot);
  } else if (cacheCurrBot && !stateCurrBot) {
    dispatch(setCurrentBotReduxAction(cacheCurrBot));
  } else if (!cacheCurrBot && !stateCurrBot) {
    if (allBotItems && allBotItems[0]) {
      dispatch(setCurrentBotReduxAction(allBotItems[0]));
      setCurrentBot(allBotItems[0]);
    }
  }

  let bot = appState.currentBotInstance;
  const convertBotApiToDropdown = (allBotItems = []) => {
    return allBotItems.map((aBot: any, idx: any) => {
      return {
        key: idx as any,
        label: (
          <span style={{ height: '40px', lineHeight: '40px', display: 'flex' }}>
            <Avatar src={aBot.avatar} style={{ width: '40px', height: '40px', marginRight: '10px' }} />
            <span style={{ lineHeight: '40px', alignItems: 'baseline' }}>{aBot.name}</span>
          </span>
        ),
        onClick: (e: any) => {
          // console.log(`click`, e, aBot);
          if (callback) callback();
          dispatch(setCurrentBotReduxAction(aBot));
        },
        // disabled: false,
      };
    });
  };
  let modelLabel = bot?.chatModel?.model || '';
  return (
    <Dropdown menu={{ items: convertBotApiToDropdown(allBotItems) }}>
      <Space>
        <Avatar src={bot?.avatar} style={{ width: '40px', height: '40px' }} />
        <div>
          <div className="d-block" style={{ display: 'block' }}>
            {bot?.name}
          </div>
          <Typography.Text type="secondary">Power by {`${modelLabel} - ${bot?.chatModel?.contextLength || 0} context`}</Typography.Text>
          {/* <Typography.Text type="secondary">{`${bot?.description}`}</Typography.Text> */}
        </div>

        <DownOutlined />
      </Space>
    </Dropdown>
  );
};
export default CurrentBotComponent;
