/* eslint-disable */
import { useState, CSSProperties, useEffect } from 'react';
import { useNavigate, useLoaderData, Navigate, useSearchParams, Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { DeltaStatic } from 'quill';
import {
  Avatar,
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Drawer,
  Dropdown,
  DropDownProps,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Progress,
  Result,
  Row,
  Select,
  Space,
  Switch,
  Table,
  TableProps,
  Tabs,
  Tag,
  Tooltip,
  Typography,
  Upload,
  UploadProps,
  DatePicker,
  Rate,
} from 'antd';
import {
  AlipayOutlined,
  AppleOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
  DeleteFilled,
  DownOutlined,
  EditFilled,
  EditOutlined,
  EyeOutlined,
  FacebookOutlined,
  FileExcelFilled,
  FilePdfFilled,
  FileTextFilled,
  FileUnknownFilled,
  FileWordFilled,
  GoogleOutlined,
  HeatMapOutlined,
  LockOutlined,
  MessageOutlined,
  MobileOutlined,
  PlayCircleFilled,
  PlusCircleFilled,
  PlusCircleOutlined,
  PlusOutlined,
  ProfileFilled,
  QuestionCircleFilled,
  ReloadOutlined,
  RetweetOutlined,
  SearchOutlined,
  TaobaoOutlined,
  UploadOutlined,
  UserOutlined,
  WeiboOutlined,
} from '@ant-design/icons';
import { LoginForm, ProFormCaptcha, ProFormCheckbox, ProFormText } from '@ant-design/pro-components';
import { store, useAppDispatch, useAppSelector } from '../../store';
import { selectAuthState, loginByUserPassThunk, getUserInfoThunkAction } from '../../reduxs/authSlice';
import { selectLanuageState, selectThemeState } from '../../reduxs/themeSlice';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { loginGoogleApi } from '../../apis/authApi';
import Loading, { LoadingComponent } from '../../components/loading';
import { GPT_MODEL_3_5, GPT_MODEL_3_5_TURBO, GPT_MODEL_4, GPT_MODEL_4_TURBO, LANGUAGES, RAG_STRATEGY, ROLE_ADMIN, ROLE_USER } from '../../consts/constApp';
import { formatBytes, getCurrentBot, keyToTitle, randomTagClass } from '../../utils/appHelper';
import {
  addADUserApi,
  getAllBotApi,
  getBotByIdApi,
  getContactManagementListApi,
  getListAdUserApi,
  getListChatHistoryApi,
  getListTags,
  getListUserApi,
  getProviderApi,
  getUserByIdApi,
  listDocumentApi,
  removeDocumentApi,
  retrainVectorDataApi,
  updateBotApi,
  updateUserApi,
  uploadTrainDocument,
} from '../../apis/appApi';
import { MenuProps } from 'antd/lib';
import { BotDataType } from '../../types/appTypes';
import CurrentBotComponent from '../../components/currentBot/currentBotComponent';
import { selectAppState, setCurrentBotReduxAction } from '../../reduxs/appSlice';
import BotCreateComponent from '../../components/botCreate/botCreatecomponent';
const Paragraph = Typography.Paragraph;
const styleBreakline: any = {
  whiteSpace: 'pre-line',
  overflowWrap: 'break-word',
  wordBreak: 'break-word',
};
const { Search } = Input;
const ContactManagementPage = () => {
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [total, setTotal] = useState(0);
  const [searchCondition, setSearchCondition] = useState({
    // botId: '',
    q: '',
  } as any);
  const userInfo = useLoaderData();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuthState);
  const appState = useAppSelector(selectAppState);
  const themeState = useAppSelector(selectThemeState);
  const lang = useAppSelector(selectLanuageState);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [botItems, setBotItems] = useState<any>([]);
  const [botData, setBotData]: [BotDataType, any] = useState((getCurrentBot() as BotDataType) || {});
  const [allTags, setAllTags] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [isOpenModalCreate, setisOpenModalCreate] = useState(false);
  // const [isOpenAddADUser, setisOpenAddADUser] = useState(false);
  // const [documents, setDocuments] = useState<any>([]);
  const [contactList, setContactList] = useState<any>([]);
  // const [currentChatConversation, setCurrentChatConversation] = useState<any>(null);

  // const [showModalEditMessage, setshowModalEditMessage] = useState(false);
  // const [currentMessage, setcurrentMessage] = useState(null);
  // const [activeCollapseIndex, setActiveCollapseIndex] = useState(1);

  const loadContactList = async (params: any) => {
    let q = params['q'];
    let pageNew = params['page'] || page;
    let limitNew = params['limit'] || limit;
    setLoading(true);
    try {
      let result = await getContactManagementListApi({
        page: pageNew,
        limit: limitNew,
        search: {
          ...searchCondition,
          q: q != undefined ? q : searchCondition.q || '',
        },
      });
      let items = result.items.map((r: any, idx: number) => {
        return {
          ...r,
          key: r.id || idx,
        };
      });
      setContactList(
        items.map((r: any) => {
          // if (r?.tags && r?.tags?.length > 0) {
          //   for (let tag of r?.tags) {
          //     let k = String(tag).toLowerCase();
          //     if (!mapTagClass[k]) {
          //       mapTagClass[k] = randomTagClass();
          //     }
          //   }
          // }
          return r;
        })
      );
      setTotal(result.total);
      // if (!currentChatConversation) {
      //   setCurrentChatConversation(items[0] || null);
      // }
    } catch (error) {
      console.error(error);
      message.error(((error as any) || {})['message'] || JSON.stringify(error));
    }
    setLoading(false);
  };

  const initFn: any = async () => {
    const [_, result, tagsData] = await Promise.all([loadContactList({}), getAllBotApi(), getListTags({})]);
    setBotItems(result.items);
    setAllTags(tagsData.items);
    console.log(`loadContactList`, tagsData.items);
  };

  useEffect(() => {
    initFn();
  }, []);

  const columns: TableProps<any>['columns'] = [
    {
      title: keyToTitle('fullName'),
      dataIndex: 'fullName',
      key: 'text',
      render: (text, record) => <>{String(record.fullName).toString()}</>,
    },
    {
      title: keyToTitle('email'),
      dataIndex: 'email',
      key: 'text',
      render: (text, record) => <>{String(record.email).toString()}</>,
    },
    {
      title: keyToTitle('note'),
      dataIndex: 'note',
      key: 'text',
      render: (text, record) => <>{String(record.note).toString()}</>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title={'Edit'}>
            <EditOutlined
              onClick={(e) => {
                // setShowModalEdit(true);
                // setCurrentVectorData(record);
              }}
              style={{ color: 'orange' }}
            />
          </Tooltip>

          <Tooltip title={'View'}>
            <EyeOutlined
              onClick={(e) => {
                // setCurrentVectorData(record);
                // setShowModalView(true);
              }}
              style={{ color: 'blueviolet' }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    initFn();
  }, []);

  return (
    <div className="list-bot-instance">
      <Card
        title={
          'Contact Management'
          // <CurrentBotComponent
          //   allBotItems={botItems}
          //   // callback={(selectBot:any) => {
          //   //   if(selectBot)
          //   //   setBotData(selectBot);
          //   // }}
          // />
        }
      >
        {loading && <LoadingComponent />}

        <div className="list">
          <Row style={{ marginBottom: '15px' }}>
            <Col span={12}>
              <Search
                placeholder="input search text"
                loading={loading}
                allowClear={true}
                onSearch={async (value, _e, info) => {
                  console.log(`on change search`, value);
                  setSearchCondition({
                    ...searchCondition,
                    q: value || '',
                  });
                  await loadContactList({
                    q: value || '',
                  });
                }}
                enterButton
              />
            </Col>
            {/* <Col span={12} style={{ textAlign: 'right' }}>
              <div className="text-right">
                <Button
                  type="primary"
                  onClick={(e) => {
                    setShowModalAdd(true);
                    setCurrentVectorData({});
                  }}
                >
                  <PlusCircleFilled />
                  Add Vector Data
                </Button>
              </div>
            </Col> */}
          </Row>

          <Table
            columns={columns}
            dataSource={contactList.map((r: any, idx: number) => {
              return {
                ...r,
                key: r.idx + String(idx),
              };
            })}
            pagination={false}
            style={{ marginBottom: '15px' }}
          />
          <Pagination
            current={page}
            pageSize={limit}
            total={total}
            onChange={(pageCb, pageSizeCb) => {
              console.log(`change page`, pageCb, pageSizeCb);
              if (limit !== pageSizeCb) {
                setLimit(pageSizeCb);
              }
              setPage(pageCb);
              loadContactList({ page: pageCb, limit: pageSizeCb || limit });
            }}
          />
        </div>
      </Card>
    </div>
  );
};

export default ContactManagementPage;
