import { DownOutlined, MessageOutlined } from '@ant-design/icons';
import { Spin, Dropdown, Space, Typography, Input, Button } from 'antd';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectAppState, setCurrentBotReduxAction } from '../../reduxs/appSlice';
import { isLocalhost, setBotForPreviewWebChat } from '../../utils/appHelper';

const PreviewWebChatComponent = ({ botData }: any) => {
  const dispatch = useAppDispatch();
  const appState = useAppSelector(selectAppState);
  // const [bot, setBot] = useState(botData || getCurrentBot() || {});
  let domainPreivew = process.env.REACT_APP_WEBCHAT_BASE_URL + '/';
  let value = domainPreivew + String(botData?.id).replace(/\s/g, '').toLowerCase();
  let url = (isLocalhost() ? 'http://localhost:4000/' : domainPreivew) + botData?.id;
  return (
    <Input
      type="text"
      value={value}
      suffix={
        <Button
          type="primary"
          onClick={(e) => {
            setBotForPreviewWebChat(botData);
            console.log(`open tab bot`, botData?.name);
            window.open(url);
          }}
        >
          <MessageOutlined />
          Chat
        </Button>
      }
    />
  );
};
export default PreviewWebChatComponent;
