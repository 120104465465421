/* eslint-disable */
import { COOKIES_TOKEN_KEY, COOKIES_USER_KEY, TOKEN_AUTH_KEY, USER_AUTH_KEY } from '../consts/constApp';
import Cookies from 'js-cookie';
import { isLocalhost } from './appHelper';
export const getWildcardCookieDomain = () => {
  let domain = isLocalhost() ? 'localhost' : process.env.REACT_APP_COOKIES_DOMAIN;
  return domain;
};

export function isAuthenticated() {
  let token = getAuthToken();
  let user = getAuthUser();
  return !!(token && user);
}

export function getAuthToken() {
  return localStorage.getItem(TOKEN_AUTH_KEY);
}
export function getAuthUser() {
  let v: any = localStorage.getItem(USER_AUTH_KEY);
  try {
    return JSON.parse(v);
  } catch (error) {
    return null;
  }
}
export const getTokenCookies = () => {
  let wildcardDomain = getWildcardCookieDomain();
  return Cookies.get(COOKIES_TOKEN_KEY);
};
export const getUserCookies = () => {
  let wildcardDomain = getWildcardCookieDomain();
  let v: any = Cookies.get(COOKIES_USER_KEY);
  try {
    return JSON.parse(v);
  } catch (error) {
    return null;
  }
};

export const setLocalStorageAndCookies = ({ token, user }: { user?: any; token?: any }) => {
  let wildcardDomain = getWildcardCookieDomain();
  if (token) {
    localStorage.setItem(TOKEN_AUTH_KEY, token);
    Cookies.set(COOKIES_TOKEN_KEY, String(token).toString(), { expires: 7, domain: wildcardDomain }); //7day
  }
  if (user) {
    localStorage.setItem(USER_AUTH_KEY, JSON.stringify(user));
    Cookies.set(COOKIES_USER_KEY, JSON.stringify(user), { expires: 7, domain: wildcardDomain }); //7day
  }
};
export const removeLocalStorageAndCookies = () => {
  let wildcardDomain = getWildcardCookieDomain();
  localStorage.removeItem(TOKEN_AUTH_KEY);
  localStorage.removeItem(USER_AUTH_KEY);
  Cookies.remove(COOKIES_TOKEN_KEY, { domain: wildcardDomain });
  Cookies.remove(COOKIES_USER_KEY, { domain: wildcardDomain });
};
export const initAuthStateRedux = () => {
  let tokenStorage = getAuthToken();
  let tokenCookies = getTokenCookies();
  let userStorate = getAuthUser();
  let userCookies = getUserCookies();
  if (tokenCookies && !tokenStorage) {
    setLocalStorageAndCookies({ token: tokenCookies });
  }
  if (userCookies && !userStorate) {
    setLocalStorageAndCookies({ user: userCookies });
  }
  // console.log(`init auth state from cookies & storage `, {
  //   tokenStorage, tokenCookies, userStorate, userCookies
  // })
  if (!tokenCookies) {
    //k co cookie => logout
    removeLocalStorageAndCookies();
    return { token: '', user: null };
  }
  return {
    token: tokenCookies || tokenStorage || '',
    user: userCookies || userStorate || null,
  };
};
