import { useState, CSSProperties, useEffect } from 'react';
import { useNavigate, useLoaderData, Navigate, useSearchParams, Link } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  DropDownProps,
  Form,
  Input,
  message,
  Modal,
  Progress,
  Result,
  Row,
  Select,
  Space,
  Switch,
  Table,
  TableProps,
  Tabs,
  Tag,
  Tooltip,
  Upload,
  UploadProps,
} from 'antd';
import {
  AlipayOutlined,
  AppleOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
  DeleteFilled,
  DownOutlined,
  EditFilled,
  FacebookOutlined,
  FileExcelFilled,
  FileExcelOutlined,
  FilePdfFilled,
  FileTextFilled,
  FileUnknownFilled,
  FileWordFilled,
  GoogleOutlined,
  LockOutlined,
  MessageOutlined,
  MobileOutlined,
  PlusCircleFilled,
  TaobaoOutlined,
  UploadOutlined,
  UserOutlined,
  WeiboOutlined,
} from '@ant-design/icons';
import { LoginForm, ProFormCaptcha, ProFormCheckbox, ProFormText } from '@ant-design/pro-components';
import { store, useAppDispatch, useAppSelector } from '../store';
import { selectAuthState, loginByUserPassThunk, getUserInfoThunkAction } from '../reduxs/authSlice';
import { selectLanuageState, selectThemeState } from '../reduxs/themeSlice';
import { useTranslation } from 'react-i18next';
import { loginGoogleApi } from '../apis/authApi';
import Loading, { LoadingComponent } from '../components/loading';
import Paragraph from 'antd/lib/skeleton/Paragraph';
import { GPT_MODEL_3_5, GPT_MODEL_3_5_TURBO, GPT_MODEL_4, GPT_MODEL_4_TURBO, LANGUAGES, RAG_STRATEGY } from '../consts/constApp';
import { formatBytes, getCurrentBot, keyToTitle, timeout } from '../utils/appHelper';
import { getAllBotApi, getBotByIdApi, getProviderApi, listDocumentApi, removeDocumentApi, updateBotApi, uploadTrainDocument } from '../apis/appApi';
import { MenuProps } from 'antd/lib';
import * as _ from 'lodash';
import { BotDataType } from '../types/appTypes';
import CurrentBotComponent from '../components/currentBot/currentBotComponent';
import { selectAppState, setCurrentBotReduxAction } from '../reduxs/appSlice';
import PreviewWebChatComponent from '../components/previewWebChat/previewWebChatComponent';
import { EXCEL_MINE_TYPES, MINE_EXCEL, MINE_PDF, MINE_TEXT, MINE_WORD, PDF_MINE_TYPES, TEXT_MINE_TYPES, WORD_MINE_TYPES } from '../consts/mineTypesConst';
const MINE_EXCEL_FnQ = MINE_EXCEL + '_fnq';
const BotInstanceTrainDataPage = () => {
  const { t, i18n } = useTranslation();
  const userInfo = useLoaderData();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuthState);
  const appState = useAppSelector(selectAppState);
  const themeState = useAppSelector(selectThemeState);
  const lang = useAppSelector(selectLanuageState);
  const [searchParams, setSearchParams] = useSearchParams();
  const [botItems, setBotItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSubmittingUpload, setIsSubmittingUpload] = useState(false);
  const [isModalOpenUpload, setIsModalOpenUpload] = useState(false);
  const [documents, setDocuments] = useState<any>([]);
  const [fileList, setFileList] = useState<any>([]);
  const [currentDocument, setCurrentDocument] = useState({
    title: '',
    summary: '',
    fileType: '',
    progressPercent: null,
  } as any);
  // const [currentProvider, setCurrentProvider] = useState<any>(null);
  const [botData, setBotData]: [BotDataType, any] = useState(null as any);

  const columns: TableProps<any>['columns'] = [
    {
      title: 'Summary',
      dataIndex: 'summary',
      key: 'summary',
      render: (summary) => <span>{summary}</span>,
    },
    {
      title: 'File',
      dataIndex: 'filePath',
      key: 'filePath',
      render: (filePath) => <span>{filePath}</span>,
    },

    {
      title: 'Filetype',
      dataIndex: 'fileType',
      key: 'fileType',
      render: (value): any => {
        switch (String(value).toLowerCase()) {
          case MINE_PDF:
            return <FilePdfFilled style={{ color: 'black' }} />;
          case MINE_EXCEL:
          case MINE_EXCEL_FnQ:
            return <FileExcelFilled style={{ color: 'blue' }} />;
          case MINE_WORD:
            return <FileWordFilled style={{ color: 'green' }} />;
          case MINE_TEXT:
            return <FileTextFilled style={{ color: 'orange' }} />;
          default:
            return <FileUnknownFilled style={{ color: 'yellow' }} />;
        }
      },
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      render: (size) => <span>{formatBytes(size, 0)}</span>,
    },
    {
      title: 'Vector size',
      dataIndex: 'vectorSize',
      key: 'vectorSize',
      render: (vectorSize) => <span>{vectorSize}</span>,
    },
    // {
    //   title: 'Tags',
    //   key: 'tags',
    //   dataIndex: 'tags',
    //   render: (_, { tags }) => (
    //     <>
    //       {tags.map((tag:any) => {
    //         let color = tag.length > 5 ? 'geekblue' : 'green';
    //         if (tag === 'loser') {
    //           color = 'volcano';
    //         }
    //         return (
    //           <Tag color={color} key={tag}>
    //             {tag.toUpperCase()}
    //           </Tag>
    //         );
    //       })}
    //     </>
    //   ),
    // },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <DeleteFilled
            onClick={(e) => {
              removeDocumentHandle(record);
            }}
            style={{ color: 'red' }}
          />
        </Space>
      ),
    },
  ];

  const initFn: any = async () => {
    setLoading(true);
    if (!appState.currentBotInstance) {
      let botss = await getAllBotApi();
      setBotItems(botss.items as any);
      dispatch(setCurrentBotReduxAction(botss.items[0]));
    } else {
      const [documentsRs, result] = await Promise.all([
        listDocumentApi({
          botId: appState.currentBotInstance?.id,
        }),
        getAllBotApi(),
      ]);
      let allBotItems = result.items;
      let listDocumet = documentsRs.items.map((r: any) => {
        r.key = r.id;
        return r;
      });
      setBotItems(allBotItems as any);
      setDocuments(listDocumet);
      if (!botData) {
        setBotData(appState.currentBotInstance);
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    initFn();
  }, [appState.currentBotInstance]);

  const [form] = Form.useForm();
  const layout = {
    labelCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } },
  };
  const tailLayout = {
    wrapperCol: { xs: { span: 24 }, sm: { span: 24, offset: 0 }, md: { span: 24, offset: 0 }, lg: { span: 24, offset: 0 } },
  };

  const onResetStateAndForm = async ({ closeModal }: { closeModal?: boolean }) => {
    setCurrentDocument({
      title: '',
      summary: '',
      progressPercent: null,
      fileType: null,
    });
    if (form) {
      form.setFieldsValue({ title: '', summary: '' });
      form.resetFields();
    }
    setLoading(false);
    setIsSubmittingUpload(false);
    setFileList([]);
    if (closeModal) {
      await timeout(0.1);
      setIsModalOpenUpload(false);
    }
  };

  const removeDocumentHandle = async (document: any) => {
    Modal.confirm({
      title: 'Remove document ?',
      onOk: async () => {
        setLoading(true);
        try {
          let param: any = {};
          await removeDocumentApi({
            id: document?.id,
          });

          message.success(`Remove document success`);
          // let botResult = await getBotByIdApi({ id: botData?.id });
          // dispatch(setCurrentBotReduxAction(botResult));
          await initFn();
        } catch (error) {
          console.error(error);
          message.error(JSON.stringify(error));
        }
        setLoading(false);
      },
    });
  };
  const beforeUploadHandler = (file: any) => {
    let currDocumentFileType = (currentDocument as any)?.fileType;
    if (currDocumentFileType == MINE_EXCEL_FnQ) {
      currDocumentFileType = MINE_EXCEL;
    }
    const allowFileType = currDocumentFileType;
    const fileMineType = String(file.type).toString();
    let isAllow = false;
    switch (allowFileType) {
      case MINE_PDF: {
        isAllow = PDF_MINE_TYPES.includes(fileMineType);
        break;
      }
      case MINE_EXCEL:
      case MINE_EXCEL_FnQ: {
        isAllow = EXCEL_MINE_TYPES.includes(fileMineType);
        break;
      }
      case MINE_WORD: {
        isAllow = WORD_MINE_TYPES.includes(fileMineType);
        break;
      }
      case MINE_TEXT: {
        isAllow = TEXT_MINE_TYPES.includes(fileMineType);
        break;
      }
    }

    console.info(`before upload`, { file, allowFileType, isAllow });
    if (!isAllow) {
      message.error(`File type ${fileMineType} is not allow. Please select ${allowFileType} file`);
      return false;
    }
    if (!fileList || !fileList[0]) {
      setFileList([file]);
    } else if (file?.name != fileList[0].name) {
      setFileList([file]);
    }
    return true;
  };
  const submitUploadDocument = async () => {
    try {
      let validFormRes = await form.validateFields();
    } catch (e) {
      console.error(`validFormRes error `, e);
      return;
    }
    // setLoading(true);
    setIsSubmittingUpload(true);
    try {
      let fileData = fileList && fileList[0];
      if (fileData) {
        let uploadRes = await uploadTrainDocument({
          title: form.getFieldValue('title'),
          fileType: currentDocument?.fileType,
          summary: form.getFieldValue('summary'),
          fileObject: fileData,
          botId: botData?.id || '',
          onProgressEventCb: (progressEvent: ProgressEvent) => {
            setCurrentDocument({
              ...currentDocument,
              progressPercent: Math.round((progressEvent.loaded * 100) / progressEvent.total),
            });
          },
        });
      }
      await timeout(1);
      setCurrentDocument({
        ...currentDocument,
        progressPercent: 100,
      });
      await timeout(1);
      message.success(`Upload document success`);
      await onResetStateAndForm({ closeModal: true });
      await initFn();
    } catch (error: any) {
      console.error(error);
      // if(error?.errorFields[0]){
      // message.error(error?.errorFields[0].errors[0])
      // }else{
      message.error(`${error}` || JSON.stringify(error));
      // }
    }
    // setLoading(false);
    setIsSubmittingUpload(false);
  };
  const progressBarPercentValue = () => {
    let v = (currentDocument as any)?.progressPercent;
    if (v >= 100) {
      return 90;
    }
    return _.random(v, 90);
  };

  const labelBtnUploadDocment = (document: any) => {
    let fileType = (currentDocument as any)?.fileType;
    if (fileType == MINE_EXCEL_FnQ) {
      fileType = MINE_EXCEL;
    }
    return `Choose ${fileType} file`;
  };

  return (
    <div className="train-data">
      <Card
        title={
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <CurrentBotComponent
                allBotItems={botItems}
                // callback={(selectBot: any) => {
                //   setBotData(selectBot);
                // }}
              />
            </Col>
            <Col span={12}>
              <PreviewWebChatComponent botData={botData} />
            </Col>
          </Row>
        }
      >
        {loading && <LoadingComponent />}
        <div className="upload-document">
          <Row>
            <Col span={8}>
              <Dropdown
                menu={{
                  items: [
                    {
                      label: 'Pdf',
                      key: MINE_PDF,
                      icon: <FilePdfFilled />,
                    },
                    {
                      label: 'Excel',
                      key: MINE_EXCEL,
                      icon: <FileExcelFilled />,
                    },
                    {
                      label: 'Word',
                      key: MINE_WORD,
                      icon: <FileWordFilled />,
                    },
                    {
                      label: 'Txt',
                      key: MINE_TEXT,
                      icon: <FileTextFilled />,
                    },
                    {
                      label: 'Excel FnQ',
                      key: MINE_EXCEL_FnQ,
                      icon: <FileExcelOutlined />,
                    },
                  ],
                  onClick: (e) => {
                    if (form) form.resetFields();
                    setIsModalOpenUpload(true);
                    // currentDocument.fileType = e.key;
                    setFileList([]);
                    setCurrentDocument({
                      ...(currentDocument || {}),
                      fileType: e.key,
                    } as any);
                  },
                }}
              >
                <Button>
                  <Space>
                    <PlusCircleFilled />
                    Add document
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Col>
          </Row>
        </div>
        <div className="table-document">
          <Table columns={columns} dataSource={documents} />
        </div>
      </Card>

      <Modal
        title="Upload Document"
        open={isModalOpenUpload}
        okText={'Upload'}
        cancelText={'Cancel'}
        onOk={async (e) => {
          submitUploadDocument();
        }}
        onCancel={() => {
          onResetStateAndForm({ closeModal: true });
        }}
        okButtonProps={{ disabled: isSubmittingUpload }}
      >
        <div className="text-left">
          {isModalOpenUpload && (
            <Form
              {...layout}
              // layout='horizontal'
              layout="vertical"
              form={form}
              name="control-hooks"
              // onFinish={onFinish}
              title="Bot Setting"
              // style={{ maxWidth: 600 }}
              className="form-edit-bot-instance bot-instance-profile"
            >
              <Form.Item
                initialValue={(currentDocument as any)['title']}
                key={'title'}
                // className="form-item-label-has-subtitle"
                name={'title'}
                label={
                  <>
                    <span className="title">{keyToTitle('title')}</span>
                    {/* <span className="subtitle" style={{ left: 0 }}>
                    {'title'}
                  </span> */}
                  </>
                }
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={' '}
                  onChange={(e) => {
                    (currentDocument as any)['title'] = e.target.value;
                    setBotData(currentDocument);
                  }}
                  value={(currentDocument as any)['title' as string]}
                  // defaultValue={(botData as any)[key] as string}
                  disabled={isSubmittingUpload}
                />
              </Form.Item>
              <Form.Item
                initialValue={(currentDocument as any)['summary']}
                key={'summary'}
                className="form-item-label-has-subtitle"
                name={'summary'}
                label={
                  <>
                    <span className="title">{keyToTitle('summary')}</span>
                    <span className="subtitle" style={{ left: 0 }}>
                      {'A short summary of this document. It helps AI know this document well.'}
                    </span>
                  </>
                }
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={' '}
                  onChange={(e) => {
                    (currentDocument as any)['summary'] = e.target.value;
                    setBotData(currentDocument);
                  }}
                  value={(currentDocument as any)['summary' as string]}
                  // defaultValue={(botData as any)[key] as string}
                  disabled={isSubmittingUpload}
                />
              </Form.Item>
              <Form.Item
                initialValue={(currentDocument as any)['file']}
                key={'file'}
                className="form-item-label-has-subtitle"
                name={'file'}
                label={
                  <>
                    <span className="title">{keyToTitle('File')}</span>
                    <span className="subtitle" style={{ left: 0 }}>
                      {'Maximum 30Mb, support ' + (currentDocument as any)?.fileType}
                    </span>
                  </>
                }
                rules={[{ required: true }]}
                getValueFromEvent={({ file }) => file.originFileObj}
              >
                <Upload
                  name="file"
                  maxCount={1}
                  showUploadList={false}
                  beforeUpload={(file) => {
                    beforeUploadHandler(file);
                  }}
                  onChange={({ file, fileList, event }: any) => {
                    console.info(`onchange file upload `, { file, fileList, event });
                  }}
                  customRequest={({ file, onSuccess }) => {
                    setTimeout(() => {
                      (onSuccess as any)({}, undefined);
                    }, 0);
                  }}
                  fileList={fileList}
                  // disabled={fileList && fileList[0]}
                >
                  {fileList && fileList[0] ? (
                    <Button icon={<EditFilled />}>{`${fileList[0].name}`}</Button>
                  ) : (
                    <Button disabled={isSubmittingUpload} icon={<UploadOutlined />}>
                      {labelBtnUploadDocment(currentDocument)}
                    </Button>
                  )}
                </Upload>
              </Form.Item>
              <Form.Item initialValue={(currentDocument as any)['Progress']} key={'Progress'} className="form-item-label-has-subtitle" name={'Progress'} label={null} rules={[{ required: false }]}>
                {(currentDocument as any)?.progressPercent && <Progress percent={progressBarPercentValue()} size="small" status="active" strokeColor={{ from: '#108ee9', to: '#87d068' }} />}
              </Form.Item>
              <Divider />
            </Form>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default BotInstanceTrainDataPage;
