/* eslint-disable */
import React from 'react';
import { useState, CSSProperties, useEffect } from 'react';
import { useNavigate, useLoaderData, Navigate, useSearchParams, Link } from 'react-router-dom';
import {
  Avatar,
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Drawer,
  Dropdown,
  DropDownProps,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Progress,
  Result,
  Row,
  Select,
  Space,
  Switch,
  Table,
  TableProps,
  Tabs,
  Tag,
  Tooltip,
  Typography,
  Upload,
  UploadProps,
  DatePicker,
  Rate,
} from 'antd';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  FieldNumberOutlined,
  FileProtectOutlined,
  FolderOpenOutlined,
  GoogleOutlined,
  HeatMapOutlined,
  LockOutlined,
  MessageOutlined,
  MobileOutlined,
  PlayCircleFilled,
  PlusCircleFilled,
  PlusCircleOutlined,
  PlusOutlined,
  ProfileFilled,
  QuestionCircleFilled,
  ReloadOutlined,
  RetweetOutlined,
  RiseOutlined,
  SearchOutlined,
  StarFilled,
  TaobaoOutlined,
  UploadOutlined,
  UserOutlined,
  WeiboOutlined,
} from '@ant-design/icons';
import { LoginForm, ProFormCaptcha, ProFormCheckbox, ProFormText } from '@ant-design/pro-components';
import { store, useAppDispatch, useAppSelector } from '../store';
import { selectAuthState, loginByUserPassThunk, getUserInfoThunkAction } from '../reduxs/authSlice';
import { selectLanuageState, selectThemeState } from '../reduxs/themeSlice';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { loginGoogleApi } from '../apis/authApi';
import Loading, { LoadingComponent } from '../components/loading';
import Paragraph from 'antd/lib/skeleton/Paragraph';
import { GPT_MODEL_3_5, GPT_MODEL_3_5_TURBO, GPT_MODEL_4, GPT_MODEL_4_TURBO, LANGUAGES, RAG_STRATEGY, ROLE_ADMIN, ROLE_USER } from '../consts/constApp';
import { formatBytes, getCurrentBot, keyToTitle, randomTagClass } from '../utils/appHelper';
import { getAllBotApi, loadReportApi } from '../apis/appApi';
import { MenuProps } from 'antd/lib';
import { BotDataType } from '../types/appTypes';
import { selectAppState, setCurrentBotReduxAction } from '../reduxs/appSlice';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  // Tooltip,
  Legend,
  Tooltip as TooltipChart,
  PointElement,
  LineElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, TooltipChart, Legend);

const HomePage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const userInfo = useLoaderData();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuthState);
  const appState = useAppSelector(selectAppState);
  const themeState = useAppSelector(selectThemeState);
  const lang = useAppSelector(selectLanuageState);
  const [searchParams, setSearchParams] = useSearchParams();
  const [botItems, setBotItems] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpenModalCreate, setisOpenModalCreate] = useState(false);
  const [isOpenAddADUser, setisOpenAddADUser] = useState(false);
  const [documents, setDocuments] = useState<any>([]);
  const [chatConversation, setChatConversation] = useState<any>([]);
  const [currentChatConversation, setCurrentChatConversation] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [total, setTotal] = useState(0);
  const [reportData, setReportData] = useState({} as any);
  const [searchCondition, setSearchCondition] = useState({
    from: '',
    to: '',
    tags: '',
    botId: '',
    tagType: 'feedback',
  } as any);
  const initFn: any = async (params: any = null) => {
    setLoading(true);
    const [result, _report] = await Promise.all([
      getAllBotApi(),
      loadReportApi({
        from: params?.from || searchCondition.from,
        to: params?.to || searchCondition.to,
      }),
    ]);
    setBotItems(result.items);
    setReportData(_report);
    console.log(`report data `, _report);
    setLoading(false);
  };

  useEffect(() => {
    initFn();
  }, [searchCondition.from, searchCondition.to]);

  return (
    <div className="homePage">
      {loading && <LoadingComponent />}
      <div>
        <DatePicker.RangePicker
          className="range-picker"
          // onFocus={(_, info) => {
          //   console.log('Focus:', info.range);
          // }}
          // onBlur={(_, info) => {
          //   console.log('Blur:', info.range);
          // }}
          onChange={(dates, dateStrings) => {
            console.log(`on change range picker`, dateStrings);
            setSearchCondition({
              ...searchCondition,
              from: dateStrings[0] || '',
              to: dateStrings[1] || '',
            });
            // initFn({
            //   from: dateStrings[0] || '',
            //   to: dateStrings[1] || '',
            // });
          }}
        />
      </div>
      <Row gutter={[16, 16]} style={{ marginBottom: 15 }}>
        <Col span={6}>
          <div className="general-block">
            <span className="icon">
              <FieldNumberOutlined style={{ color: 'green' }} />
            </span>
            <div className="title">{botItems?.length}</div>
            <div className="description">Number of bots</div>
            <div className="up-down">
              <ArrowUpOutlined color="red" style={{ color: 'red' }} />
              <span className="since-last-month">since last month</span>
            </div>
          </div>
        </Col>
        {/* Knowledge Documents  | Knowledge Size | Total Users */}
        <Col span={6}>
          <div className="general-block">
            <span className="icon">
              <FolderOpenOutlined style={{ color: 'blue' }} />
            </span>
            <div className="title">{reportData?.generalData?.knowledgeData?.numDocuments}</div>
            <div className="description">Knowledge Documents</div>
            <div className="up-down">
              <ArrowDownOutlined style={{ color: 'blue' }} />
              <span className="since-last-month">since last month</span>
            </div>
          </div>
        </Col>

        <Col span={6}>
          <div className="general-block">
            <span className="icon">
              <FileProtectOutlined style={{ color: 'red' }} />
            </span>
            <div className="title">{Number(reportData?.generalData?.knowledgeData?.sizeDocuments).toLocaleString()}</div>
            <div className="description">Knowledge Size</div>
            <div className="up-down">
              <ArrowDownOutlined style={{ color: 'red' }} />
              <span className="since-last-month">since last month</span>
            </div>
          </div>
        </Col>
        <Col span={6}>
          <div className="general-block">
            <span className="icon">
              <RiseOutlined style={{ color: 'greenyellow' }} />
            </span>
            <div className="title">{Number(reportData?.generalData?.knowledgeData?.totalVectors).toLocaleString()}</div>
            <div className="description">Number vectors</div>
            <div className="up-down">
              <ArrowDownOutlined style={{ color: 'red' }} />
              <span className="since-last-month">since last month</span>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: 15 }}>
        <Col span={8}>
          <div className="chart-item">
            <Bar
              // style={{width:'100%'}}
              style={{
                width: '100%',
                height: 300,
              }}
              data={{
                labels: reportData?.voteChart?.labels,
                datasets: [
                  {
                    label: reportData?.voteChart?.datasets[0].label,
                    backgroundColor: [
                      // "#3e95cd",
                      // "#8e5ea2",
                      // "#3cba9f",
                      // "#e8c3b9",
                      // "#c45850"
                      '#ff5500',
                    ],
                    data: reportData?.voteChart?.datasets[0]?.data,
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                aspectRatio: 1,
                // responsive: true,
                plugins: {
                  legend: {
                    position: 'top' as const,
                  },
                  title: {
                    display: true,
                    text: reportData?.voteChart?.chartName,
                  },
                },
              }}
            />
          </div>
        </Col>

        <Col span={8}>
          <div className="chart-item">
            <Bar
              style={{
                width: '100%',
                height: 300,
              }}
              data={{
                labels: reportData?.knowledgeChart?.labels,
                datasets: [
                  {
                    label: reportData?.knowledgeChart?.datasets[0].label,
                    backgroundColor: [
                      '#3e95cd',
                      '#8e5ea2',
                      '#3cba9f',
                      '#e8c3b9',
                      // "#c45850",
                      '#ff5500',
                    ],
                    data: reportData?.knowledgeChart?.datasets[0]?.data,
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                aspectRatio: 1,
                responsive: true,
                plugins: {
                  legend: {
                    position: 'top' as const,
                  },
                  title: {
                    display: true,
                    text: reportData?.knowledgeChart?.chartName,
                  },
                },
              }}
            />
          </div>
        </Col>

        <Col span={8}>
          <div className="chart-item">
            <Line
              style={{
                width: '100%',
                height: 300,
              }}
              data={{
                labels: reportData?.interactionChart?.labels,
                datasets: [
                  {
                    label: reportData?.interactionChart?.datasets[0]?.label,
                    data: reportData?.interactionChart?.datasets[0]?.data,
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                  },
                  {
                    label: reportData?.interactionChart?.datasets[1]?.label,
                    data: reportData?.interactionChart?.datasets[1]?.data,
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                aspectRatio: 1,
                responsive: true,
                plugins: {
                  legend: {
                    position: 'top' as const,
                  },
                  title: {
                    display: true,
                    text: reportData?.interactionChart?.chartName,
                  },
                },
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HomePage;
