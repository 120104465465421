/* eslint-disable */
import React, { useEffect } from 'react';
import en_US from 'antd/locale/en_US';
import vi_VN from 'antd/locale/vi_VN';
import logo from './logo.svg';
import './App.scss';

import { RouterProvider, createBrowserRouter, createHashRouter } from 'react-router-dom';
import { routes } from './routes/routes';

import { ConfigProvider } from 'antd';
import { useAppDispatch, useAppSelector } from './store';
import { selectLanuageState, selectThemeState } from './reduxs/themeSlice';
import { LANG_EN } from './consts/constApp';
import { useTranslation } from 'react-i18next';
import { getAllRagModelApi } from './apis/appApi';
import { setRagModelReduxAction } from './reduxs/appSlice';
import { selectAuthState } from './reduxs/authSlice';
// import { themeColor } from '@/features/theme/themeSlice'

export default function App() {
  const createdRoutes = createBrowserRouter(routes);
  // import.meta.env.VITE_ROUTER_HISTORY === 'hash'
  //   ? createHashRouter(routes)
  //   : createBrowserRouter(routes)
  const dispatch = useAppDispatch();
  // const primaryColor = useAppSelector(themeColor)
  const themeState = useAppSelector(selectThemeState);
  const authState = useAppSelector(selectAuthState);
  const lang = useAppSelector(selectLanuageState);
  const { i18n } = useTranslation();
  if (lang && i18n.language != lang) {
    i18n.changeLanguage(lang);
  }
  useEffect(() => {
    (async function () {
      if (authState.token && authState.user) {
        let ragMode = await getAllRagModelApi();
        dispatch(setRagModelReduxAction(ragMode));
      }
    })();
  }, [authState.token]);
  return (
    <ConfigProvider
      locale={lang == LANG_EN ? en_US : vi_VN}
      theme={{
        token: {
          ...themeState,
          colorPrimary: themeState.colorPrimary,
          colorInfo: themeState.colorInfo,
          colorError: themeState.colorError,
          colorSuccess: themeState.colorSuccess,
          colorWarning: themeState.colorWarning,
        },
      }}
    >
      <RouterProvider router={createdRoutes} />
    </ConfigProvider>
  );
}
