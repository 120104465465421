/* eslint-disable */
import axios from 'axios';
import { API_URL, TOKEN_AUTH_KEY, USER_AUTH_KEY } from '../consts/constApp';
import { getCurrentBot } from '../utils/appHelper';
import { removeLocalStorageAndCookies } from '../utils/authHelper';

const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    'content-type': 'application/json',
    accept: 'application/json',
  },
  timeout: 60000,
});
apiClient.defaults.timeout = 60000;

apiClient.interceptors.request.use(
  function (config) {
    if (!config.headers.authorization) {
      if (localStorage.getItem(TOKEN_AUTH_KEY)) {
        config.headers.authorization = 'Bearer ' + localStorage.getItem(TOKEN_AUTH_KEY);
      }
    }
    let contentType = config.headers['content-type'] || config.headers.getContentType();
    if (!contentType) {
      config.headers['content-type'] = 'application/json';
      config.headers.setContentType('application/json');
    }
    if (!config.headers['x-bot-id']) {
      config.headers['x-bot-id'] = getCurrentBot()?.id;
    }
    if (!config.headers['x-org-id']) {
      config.headers['x-org-id'] = getCurrentBot()?.orgId || 'b00bbeab-dd4c-46df-8e80-a42d69f689cf'.replace(/\w/g, '0');
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
apiClient.interceptors.response.use(
  function (res) {
    // console.log(res);
    return res;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.log(`axios.interceptors.response`, error);
    // message.error( error.message || error.toString() );
    // console.log(error.response.data);
    // console.log(error.response.status);
    // console.log(error.response.headers);

    if (error && error.response) {
      let response = error.response;
      if (response.status === 401 || response.statusCode === 401) {
        console.error(`API 401 error=`, error);
        console.error(`API 401 response=`, response);
        removeLocalStorageAndCookies();

        // window.localStorage.clear();
        let curretHref = window.location.href;
        if (String(curretHref).includes('login') == false) {
          window.location.href = '/login';
          return;
        }
        // console.log(window.location.href);
        // if(response.config && String(response.config.url).includes('/login') == false){
        //   console.log(`API 401 redirect signin`, response.config);
        //   window.location.href = "/signin";
        //   return;
        // }
      }
      if (error.response.data) {
        return Promise.reject(error.response.data);
      }
    }
    if (error && error.response && error.response.data) {
      if (error.response.data.errors) {
        if (error.response.data.errors.message) return Promise.reject(error.response.data.errors.message);
        return Promise.reject(JSON.stringify(error.response.data.errors));
      }

      if (error.response.data.error) {
        if (error.response.data.error.message) return Promise.reject(error.response.data.error.message);
        return Promise.reject(JSON.stringify(error.response.data.error));
      }

      if (error.response.data) {
        if (error.response.data.message) return Promise.reject(error.response.data.message);
        return Promise.reject(JSON.stringify(error.response.data));
      }
    }
    return Promise.reject(error);
  }
);

// instance.getFullListData = async (funcApi, params = {}, options = {}) => {
//   let items = [];
//   let limit = params.limit || 1000;
//   let page = 0;
//   let total = 0;
//   let totalPage = 0;
//   try {
//     let rsFirstPage = await funcApi({
//       ...params,
//       page: 0,
//       limit,
//     });
//     if (rsFirstPage.data.total) {
//       // note total old version is totalPage ?????
//       total = rsFirstPage.data.total;
//       totalPage = Math.ceil(total / limit);
//     }
//     if (Array.isArray(rsFirstPage.data.items)) {
//       items = rsFirstPage.data.items;
//     } else if (Array.isArray(rsFirstPage.data.data)) {
//       items = rsFirstPage.data.data;
//     }
//     // console.log(`rsFirstPage`, rsFirstPage);
//     if (total == 0) {
//       return items || [];
//     }
//     if (totalPage <= 1) {
//       return items;
//     }
//     if (totalPage > 1) {
//       let arrPromise = [];
//       for (page = 1; page < totalPage; page++) {
//         arrPromise.push(
//           funcApi({
//             ...params,
//             limit,
//             page,
//           })
//         );
//       }
//       if (arrPromise.length > 0) {
//         // console.log(`arrPromise`, arrPromise, `totalPage`, totalPage);
//         let rsPromies = await Promise.all(arrPromise);
//         for (let rs of rsPromies) {
//           // console.log(`rs perPage`, rs);
//           if (rs.data && Array.isArray(rs.data.items)) {
//             items = [...items, ...rs.data.items];
//           } else if (rs.data && Array.isArray(rs.data.data)) {
//             items = [...items, ...rs.data.data];
//           }
//         }
//       }
//     }
//     return items;
//   } catch (e) {
//     console.log(`[ERROR] getFullListData error`, e);
//     throw e;
//   }
// };

export { apiClient };
