/* eslint-disable */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { LANG_EN, LANG_VN, THEME_KAOLA, THEME_MAIN, THEME_TLN, themeKaolaSetting, themeMainSetting, themeTlnSetting } from '../consts/constApp';
import { getAuthToken, getAuthUser } from '../utils/authHelper';
import { getLangCache, setLangCache } from '../utils/i18nHelper';

export interface ThemeState {
  themeName?: 'main' | 'kaola' | string;
  colorPrimary?: string;
  colorError?: string;
  colorInfo?: string;
  colorSuccess?: string;
  colorWarning?: string;
  lang?: string;
}

const initialState: ThemeState = {
  ...themeMainSetting,
  themeName: 'main',
  colorPrimary: themeMainSetting.colorPrimary,
  lang: getLangCache() || LANG_EN,
};
const changeTheme = (state: any, themeName: any, themeSetting: any = {}) => {
  state.themeName = themeName;
  state.colorPrimary = themeSetting.colorPrimary;
  state.colorError = themeSetting.colorError;
  state.colorInfo = themeSetting.colorInfo;
  state.colorSuccess = themeSetting.colorSuccess;
  state.colorWarning = themeSetting.colorWarning;
  for (let k in themeSetting) {
    state[k] = themeSetting[k];
  }
};
export const themeSlice = createSlice({
  name: 'themeReducer',
  initialState,

  // Các action bình thường (sync action)
  reducers: {
    changeThemeMain: (state, action: PayloadAction<any>) => {
      // console.log(`login success`, action);
      changeTheme(state, THEME_MAIN, themeMainSetting);
      // state.themeName = 'main';
      // state.colorPrimary = themeMainSetting.colorPrimary;
      // state = {
      //   ...state,
      //   ...themeMainSetting,
      // };
    },
    changeThemeKaola: (state: ThemeState, action: PayloadAction<any>) => {
      // console.log(`login success`, action);
      changeTheme(state, THEME_KAOLA, themeKaolaSetting);
      // state.themeName = 'kaola';
      // state.colorPrimary = themeKaolaSetting.colorPrimary;
      // state = {
      //   ...state,
      //   ...themeKaolaSetting,
      // };
    },
    changeThemeTln: (state: ThemeState, action: PayloadAction<any>) => {
      // console.log(`login success`, action);
      changeTheme(state, THEME_TLN, themeTlnSetting);
      // state.themeName = THEME_TLN;
      // state = {
      //   ...state,
      //   ...themeTlnSetting,
      // };
    },
    changeLanguage: (state, action) => {
      console.log(`change lang`, action);
      let lang = action.payload.lang;
      state.lang = lang;
      setLangCache(lang);
    },
    // loginError: (state, action: PayloadAction<any>) => {
    //   state.token = '';
    //   state.user = null;
    //   state.loading = false;
    // },
    // logout: (state)=>{
    //   state.token = '';
    //   state.user = null;
    //   state.loading = false;
    // }
  },

  // action async - Code logic xử lý async action
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(loginByUserPassThunk.pending, (state) => {
  //       //// Bắt đầu thực hiện action fetchCountFromApi (Promise pending)
  //     })
  //     .addCase(loginByUserPassThunk.fulfilled, (state, action) => {
  //       //// Khi thực hiện action fetchCountFromApi (Promise fulfilled)
  //     })
  //     .addCase(loginByUserPassThunk.rejected, (state) => {
  //       //// Khi thực hiện action fetchCountFromApi (Promise rejected)
  //     });
  // },
});

export const { changeThemeMain, changeThemeKaola, changeThemeTln, changeLanguage } = themeSlice.actions;
export const selectThemeState = (state: RootState) => state.theme;
export const selectLanuageState = (state: RootState) => state.theme.lang;

export default themeSlice.reducer;
