export const MINE_PDF = 'pdf';
export const MINE_WORD = 'word';
export const MINE_EXCEL = 'excel';
export const MINE_TEXT = 'text';
export const PDF_MINE_TYPES = ['application/pdf'];
export const WORD_MINE_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-word.document.macroenabled.12',
];
export const EXCEL_MINE_TYPES = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  // .xls
  'application/excel',
  //.xls
  'application/x-excel',
  //.xls
  'application/x-msexcel',
];
export const TEXT_MINE_TYPES = ['text/plain'];
