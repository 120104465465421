import { useState, CSSProperties, useEffect } from 'react';
import { useNavigate, useLoaderData, Navigate, useSearchParams, Link } from 'react-router-dom';
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  DropDownProps,
  Form,
  Input,
  message,
  Modal,
  Progress,
  Result,
  Row,
  Select,
  Space,
  Switch,
  Table,
  TableProps,
  Tabs,
  Tag,
  Tooltip,
  Upload,
  UploadProps,
} from 'antd';
import {
  AlipayOutlined,
  AppleOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
  DeleteFilled,
  DownOutlined,
  EditFilled,
  FacebookOutlined,
  FileExcelFilled,
  FilePdfFilled,
  FileTextFilled,
  FileUnknownFilled,
  FileWordFilled,
  GoogleOutlined,
  HeatMapOutlined,
  LockOutlined,
  MessageOutlined,
  MobileOutlined,
  PlayCircleFilled,
  PlusCircleFilled,
  PlusCircleOutlined,
  ProfileFilled,
  TaobaoOutlined,
  UploadOutlined,
  UserOutlined,
  WeiboOutlined,
} from '@ant-design/icons';
import { LoginForm, ProFormCaptcha, ProFormCheckbox, ProFormText } from '@ant-design/pro-components';
import { store, useAppDispatch, useAppSelector } from '../store';
import { selectAuthState, loginByUserPassThunk, getUserInfoThunkAction } from '../reduxs/authSlice';
import { selectLanuageState, selectThemeState } from '../reduxs/themeSlice';
import { useTranslation } from 'react-i18next';
import { loginGoogleApi } from '../apis/authApi';
import Loading, { LoadingComponent } from '../components/loading';
import Paragraph from 'antd/lib/skeleton/Paragraph';
import { GPT_MODEL_3_5, GPT_MODEL_3_5_TURBO, GPT_MODEL_4, GPT_MODEL_4_TURBO, LANGUAGES, RAG_STRATEGY } from '../consts/constApp';
import { formatBytes, getCurrentBot, keyToTitle } from '../utils/appHelper';
import { getAllBotApi, getBotByIdApi, getProviderApi, listDocumentApi, removeDocumentApi, updateBotApi, uploadTrainDocument } from '../apis/appApi';
import { MenuProps } from 'antd/lib';
import { BotDataType } from '../types/appTypes';
import CurrentBotComponent from '../components/currentBot/currentBotComponent';
import { selectAppState, setCurrentBotReduxAction } from '../reduxs/appSlice';
import BotCreateComponent from '../components/botCreate/botCreatecomponent';

const BotInstanceListPage = () => {
  const { t, i18n } = useTranslation();
  const userInfo = useLoaderData();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuthState);
  const appState = useAppSelector(selectAppState);
  const themeState = useAppSelector(selectThemeState);
  const lang = useAppSelector(selectLanuageState);
  const [searchParams, setSearchParams] = useSearchParams();
  const [botItems, setBotItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpenModalCreate, setisOpenModalCreate] = useState(false);
  const [isModalOpenUpload, setIsModalOpenUpload] = useState(false);
  const [documents, setDocuments] = useState<any>([]);
  const [fileList, setFileList] = useState<any>([]);
  const [currentDocument, setCurrentDocument] = useState({} as any);
  // const [currentProvider, setCurrentProvider] = useState<any>(null);
  const [botData, setBotData]: [BotDataType, any] = useState((getCurrentBot() as BotDataType) || {});

  const columns: TableProps<any>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, record, index) => (
        <>
          <Avatar src={record.avatar} />
          <span style={{ marginLeft: '5px' }}>{name}</span>
        </>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (description) => <span>{description}</span>,
    },
    // {
    //   title: 'Avatar',
    //   dataIndex: 'avatar',
    //   key: 'avatar',
    //   render: (avatar) => <Avatar src={avatar}/>,
    // },

    {
      title: 'Lang',
      dataIndex: 'lang',
      key: 'lang',
      render: (lang) => <span>{lang}</span>,
    },
    {
      title: 'Model',
      dataIndex: 'llmProvider',
      key: 'llmProvider',
      render: (llmProvider, record, index) => (
        <>
          <span>{record?.chatModel?.model}</span>
        </>
      ),
    },
    // {
    //   title: 'Strategy',
    //   dataIndex: 'ragStrategy',
    //   key: 'ragStrategy',
    //   render: (ragStrategy, record, index) => (
    //     <>
    //       <span>{ragStrategy}</span>
    //     </>
    //   ),
    // },
    {
      title: 'Temperature',
      dataIndex: 'llTemperature',
      key: 'llTemperature',
      render: (llTemperature, record, index) => (
        <>
          <span>{llTemperature}</span>
        </>
      ),
    },
    {
      title: keyToTitle('feedbackMessage'),
      dataIndex: 'feedbackMessage',
      key: 'feedbackMessage',
      render: (feedbackMessage, record, index) => (
        <>
          <span>{feedbackMessage}</span>
        </>
      ),
    },
    {
      title: keyToTitle('feedbackInSeconds'),
      dataIndex: 'feedbackInSeconds',
      key: 'feedbackInSeconds',
      render: (feedbackInSeconds, record, index) => (
        <>
          <span>{feedbackInSeconds}</span>
        </>
      ),
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record, index) => (
        <>
          <Switch
            defaultChecked
            value={status === 1}
            onChange={async (e) => {
              console.log(`change `, e);
              let list: any = [...botItems];
              list[index]['status'] = e === true ? 1 : 0;
              setLoading(true);
              try {
                await updateBotApi({ id: record.id, payload: { status: list[index]['status'] } });
                setBotItems(list);
                message.success(`Update success`);
              } catch (error) {
                console.error(error);
              }
              setLoading(false);
            }}
          />
          {/* <Tag color={status == 1 ? 'success' : 'default'} key={status}>
            {status == 1 ? 'Active' : 'Inactive'}
          </Tag> */}
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title={'Profile Setting'}>
            <ProfileFilled
              onClick={(e) => {
                dispatch(setCurrentBotReduxAction(record));
                navigate('/bot-instance-profile');
              }}
              style={{ color: 'blueviolet' }}
            />
          </Tooltip>
          <Tooltip title={'LLM Setting'}>
            <HeatMapOutlined
              onClick={(e) => {
                dispatch(setCurrentBotReduxAction(record));
                navigate('/bot-instance-llm');
              }}
              style={{ color: 'orange' }}
            />
          </Tooltip>

          <Tooltip title={'Widget Setting'}>
            <MessageOutlined
              onClick={(e) => {
                dispatch(setCurrentBotReduxAction(record));
                navigate('/bot-instance-widget');
              }}
              style={{ color: 'blue' }}
            />
          </Tooltip>

          {/* <DeleteFilled
            onClick={(e) => {
            }}
            style={{ color: 'red' }}
          /> */}
        </Space>
      ),
    },
  ];

  const initFn: any = async () => {
    setLoading(true);
    const [result] = await Promise.all([getAllBotApi()]);
    let allBotItems = result.items.map((r: any) => {
      return {
        ...r,
        key: r.id || r.botId,
      };
    });
    console.log(`allBotItems`, allBotItems);
    setBotItems(allBotItems as any);
    if (!botData.id && allBotItems && allBotItems[0]) {
      setBotData(allBotItems[0]);
    }
    if (botData?.id !== appState.currentBotInstance?.id) {
      setBotData(appState.currentBotInstance);
    }
    setLoading(false);
  };
  useEffect(() => {
    initFn();
  }, []);
  const callbackFromCreateForm = () => {
    initFn();
    setisOpenModalCreate(false);
  };
  return (
    <div className="list-bot-instance">
      <Card
        title={
          <Row>
            <Col span={12}>List Bot</Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button
                color="primary"
                type="primary"
                onClick={(e) => {
                  setisOpenModalCreate(true);
                }}
              >
                <PlusCircleOutlined /> New Bot
              </Button>
            </Col>
          </Row>
        }
      >
        {loading && <LoadingComponent />}

        <div className="list">
          <Table columns={columns} dataSource={botItems} />
        </div>
        {isOpenModalCreate && (
          <Modal
            width={'80%'}
            open={isOpenModalCreate}
            title={'Create bot'}
            footer={null}
            onCancel={(e) => {
              setisOpenModalCreate(false);
            }}
          >
            <Divider />
            <BotCreateComponent callback={callbackFromCreateForm} />
          </Modal>
        )}
      </Card>
    </div>
  );
};

export default BotInstanceListPage;
