import { configureStore, ThunkAction, Action, createListenerMiddleware } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { logger } from 'redux-logger';
import counterReducer from './reduxs/counterSlice';
import authReducer from './reduxs/authSlice';
import themeSlice from './reduxs/themeSlice';
import appReducer from './reduxs/appSlice';

const isProd = process.env.NODE_ENV === 'production';
export const store = configureStore({
  reducer: {
    counter: counterReducer,
    theme: themeSlice,
    auth: authReducer,
    app: appReducer,
  },
  middleware: (getDefaultMiddleware) => {
    let middleware = getDefaultMiddleware();
    middleware.push(createListenerMiddleware().middleware);
    if (!isProd) {
      middleware.push(logger);
    }
    // console.log(`middleware`, middleware);
    return middleware;
  },
  devTools: !isProd,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
