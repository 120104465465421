import { useState, CSSProperties, useEffect } from 'react';
import { useNavigate, useLoaderData, Navigate, useSearchParams, Link } from 'react-router-dom';
import { Button, Card, Col, Divider, Form, Input, message, Modal, Result, Row, Select, Space, Switch, Tabs, Tooltip, Typography } from 'antd';
import {
  AlipayOutlined,
  AppleOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
  FacebookOutlined,
  GoogleOutlined,
  LockOutlined,
  MobileOutlined,
  PlusCircleTwoTone,
  TaobaoOutlined,
  UserOutlined,
  WeiboOutlined,
} from '@ant-design/icons';
import { LoginForm, ProFormCaptcha, ProFormCheckbox, ProFormText } from '@ant-design/pro-components';
import { store, useAppDispatch, useAppSelector } from '../store';
import { selectAuthState, loginByUserPassThunk, getUserInfoThunkAction } from '../reduxs/authSlice';
import { selectLanuageState, selectThemeState } from '../reduxs/themeSlice';
import { useTranslation } from 'react-i18next';
import { loginGoogleApi } from '../apis/authApi';
import Loading, { LoadingComponent } from '../components/loading';
import Paragraph from 'antd/lib/skeleton/Paragraph';
import { GPT_MODEL_3_5, GPT_MODEL_3_5_TURBO, GPT_MODEL_4, GPT_MODEL_4_TURBO } from '../consts/constApp';
import { checkProviderApiKeyApi, createNewModelOfProvider, getProviderApi, updateModelOfProviderApi, updateProviderApi } from '../apis/appApi';
import { ProviderModelType, ProviderType } from '../types/appTypes';
import { String } from 'lodash';
import { keyToTitle } from '../utils/appHelper';
const { Text } = Typography;
const TAB_CHAT = 'chat';
const TAB_VOICE = 't2s';
const TAB_IMAGE = 'image';
const TAB_EMBEDDING = 'embedding';
const ApiKeyPage = () => {
  const { t, i18n } = useTranslation();
  const userInfo = useLoaderData();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuthState);
  const themeState = useAppSelector(selectThemeState);
  const lang = useAppSelector(selectLanuageState);
  const [providers, setProviders] = useState<ProviderType[]>([]);
  const [currProvider, setCurrProvider] = useState<ProviderType>(null as any);
  const [newModel, setNewModel] = useState<ProviderModelType>(null as any);
  const [viewModel, setViewModel] = useState<ProviderModelType>(null as any);
  const [loading, setLoading] = useState<any>(false);
  const [currTab, setCurrTab] = useState<any>(TAB_CHAT);
  const [viewLoadedComplete, setViewLoadedComplete] = useState<any>(false);
  const [showModalAddModel, setShowModalAddModel] = useState<boolean>(false);
  const [showModalViewModel, setShowModalViewModel] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const loadProvidersData = async (key: string = currTab, cb: any = null) => {
    let res: ProviderType[] = await getProviderApi();
    if (key) {
      res = res.filter((r: any) => r.modelType == key);
      // console.log(`provider loaded by key `, key, res);
      setProviders(res);
    }
    if (cb) cb();
  };
  useEffect(() => {
    setLoading(true);
    loadProvidersData(currTab, () => {
      setLoading(false);
      if (!viewLoadedComplete) {
        setViewLoadedComplete(true);
      }
    });
  }, []);

  const [form] = Form.useForm();
  const [formAddModel] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    // console.log(`use effect form `, providers);
  }, [form, providers]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };

  const tailLayout = {
    wrapperCol: { span: 6, offset: 0 },
  };
  const clickCheckApiKey = async (provider: any, idx: any, formItemName: any) => {
    setLoading(true);
    try {
      await checkProviderApiKeyApi({ id: provider.id, apiKey: provider.apiKey });
      message.success(`Your api key is work`);
      form.setFields([
        {
          name: formItemName,
          errors: [],
        },
      ]);
    } catch (error: any) {
      console.error(error);
      message.error(JSON.stringify(error.data || error));
      form.setFields([
        {
          name: formItemName,
          errors: ['Your api key is not work. Please try again'],
        },
      ]);
    }
    setLoading(false);
  };
  const toggleOnOffModel = async (provider: any, model: any) => {
    setLoading(true);
    try {
      await updateModelOfProviderApi({
        id: model.id,
        payload: { ...model },
      });
      message.success(`Upload model success`);
    } catch (error: any) {
      console.error(error);
      message.error(JSON.stringify(error.data || error));
    }
    setLoading(false);
  };
  const onFormSubmit = async (values: any) => {
    console.log(`form changes values = `, values);
    console.log(`state providers = `, providers);
    setLoading(true);
    for (let provider of providers) {
      try {
        const updateParam = {
          ...provider,
          models: provider.models,
        };
        console.log(`update ${provider.id} ${provider.name} params = `, updateParam);
        await updateProviderApi({
          id: provider.id,
          payload: updateParam,
        });
      } catch (error) {
        console.error(error);
        message.error(JSON.stringify(error));
      }
    }
    await loadProvidersData();
    message.success(`Upload provider success`);
    setLoading(false);
  };

  const onReset = async () => {
    setLoading(true);
    form.resetFields();
    await loadProvidersData();
    setLoading(false);
  };
  const onChangeTabModelType = async (key: string) => {
    setLoading(true);
    setCurrTab(key);
    await loadProvidersData(key, () => {
      setLoading(false);
    });
  };
  const showModalAddNewModalForProvider = (provider: ProviderType) => {
    let newModel = {
      model: '',
      modelType: provider.modelType,
      contextLength: '0',
      providerId: provider.id,
    } as ProviderModelType;
    setShowModalAddModel(true);
    setCurrProvider({ ...provider });
    setNewModel(newModel as ProviderModelType);
    console.log(`popup new model`, newModel, { provider });
    Object.keys(newModel).map((k) => {
      formAddModel.setFieldValue(k, (newModel as any)[k]);
    });
  };
  const resetCurrentItem = () => {
    formAddModel.resetFields();
    setNewModel(null as any);
    setCurrProvider(null as any);
    setViewModel(null as any);
  };
  const submitCreateNewModel = async () => {
    if (loading === true) return;
    setLoading(true);
    try {
      const updateParam = {
        ...newModel,
      };
      await createNewModelOfProvider({
        payload: {
          ...updateParam,
        },
      });
      message.success(`Add new model successfully`);
      loadProvidersData(currTab);
      resetCurrentItem();
      setShowModalAddModel(false);
    } catch (error) {
      console.error(error);
      message.error(JSON.stringify(error));
    }
    setLoading(false);
  };

  if (!viewLoadedComplete) {
    return <LoadingComponent />;
  }
  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            key: TAB_CHAT,
            label: 'Chat',
            // children: 'Content of Tab Pane 1',
          },
          {
            key: TAB_VOICE,
            label: 'Voice',
            // children: 'Content of Tab Pane 2',
          },
          {
            key: TAB_EMBEDDING,
            label: 'Embedding',
            // children: 'Content of Tab Pane 3',
          },
        ]}
        onChange={(key: string) => {
          onChangeTabModelType(key);
        }}
      />
      <Form
        {...layout}
        // layout='vertical'
        form={form}
        name="control-hooks"
        onFinish={onFormSubmit}
        // style={{ maxWidth: 600 }}
        colon={false}
      >
        <Card
        // title="Chat GPT"
        >
          {loading && <LoadingComponent />}
          {(providers || []).map((provider: ProviderType, idx: number) => {
            let providerApiKeyIdx = idx.toString() + '_' + provider.id + provider.llmProvider + '_apiKey';
            let providerModelsIdx = idx.toString() + '_' + provider.id + provider.llmProvider + '_models';
            return (
              <div key={idx}>
                <Divider orientation="left" plain>
                  {provider.name}
                </Divider>
                <Form.Item initialValue={provider.apiKey} name={`apiKey_${idx}`} label="Key" rules={[{ required: true }]} tooltip={'Input your api key here'}>
                  <Input
                    defaultValue={provider.apiKey}
                    value={provider.apiKey}
                    type="text"
                    onChange={(e) => {
                      provider.apiKey = e.target.value;
                      providers[idx] = provider;
                      setProviders(providers);
                    }}
                    placeholder="Input api key"
                    suffix={
                      <Tooltip title="Click to check your api key ">
                        <Button
                          disabled={loading}
                          type="primary"
                          onClick={(e) => {
                            console.log(`check api key`, provider, e);
                            clickCheckApiKey(provider, idx, providerApiKeyIdx);
                          }}
                        >
                          <CheckCircleFilled className="text-primary btn btn-primary" title="Check api key" style={{ cursor: 'pointer' }} />
                        </Button>
                      </Tooltip>
                    }
                  />
                </Form.Item>

                {['endpoint', 'apiVersion'].map((column: string, idxCol: number) => {
                  return (
                    <Form.Item
                      key={idxCol}
                      initialValue={(provider as any)[column]}
                      name={`${column}_${idx}`}
                      label={keyToTitle(column)}
                      rules={[{ required: false }]}
                      tooltip={'Input ' + keyToTitle(column)}
                    >
                      <Input
                        // defaultValue={provider.apiKey}
                        value={(provider as any)[column]}
                        type="text"
                        onChange={(e) => {
                          (provider as any)[column] = e.target.value;
                          providers[idx] = provider;
                          setProviders(providers);
                        }}
                        placeholder={'Input ' + keyToTitle(column)}
                      />
                    </Form.Item>
                  );
                })}

                <Form.Item label="Model" name={`models_${idx}`} rules={[{ required: false }]} tooltip={'Select your api key model'}>
                  {(provider.models || []).map((modelItem: any, idxModel: any) => {
                    const modelIdx = idxModel.toString() + '_' + modelItem.id + `${modelItem.provider}_${modelItem.model}`;
                    return (
                      <div key={idxModel} className="provider-model-item" style={{ marginBottom: '10px' }}>
                        <Row>
                          <Col span={16}>
                            <span
                              style={{ width: 'auto', display: 'inline-block', cursor: 'pointer' }}
                              onClick={(e) => {
                                setShowModalViewModel(true);
                                setCurrProvider(provider);
                                setViewModel(modelItem);
                              }}
                            >
                              <Typography.Text strong style={{ marginRight: '10px' }}>
                                {modelItem.name || 'Unknown name'}
                              </Typography.Text>
                              <Typography.Text type="secondary">{modelItem.model || modelItem.description}</Typography.Text>
                            </span>
                          </Col>
                          <Col span={8} style={{ textAlign: 'right' }}>
                            <Switch
                              disabled={loading}
                              value={modelItem.status}
                              checked={modelItem.status}
                              onChange={async (e) => {
                                // console.log(`toogle change `, modelItem, e);
                                modelItem.status = e === false ? 0 : 1;
                                provider.models[idxModel] = modelItem;
                                providers[idx] = provider;
                                await toggleOnOffModel(provider, modelItem);
                                setProviders(providers);
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                  <div>
                    <Tooltip title="Add new model">
                      <PlusCircleTwoTone
                        onClick={(e) => {
                          showModalAddNewModalForProvider(provider);
                        }}
                      />
                    </Tooltip>
                  </div>
                </Form.Item>
              </div>
            );
          })}

          <Form.Item {...tailLayout} style={{ marginTop: '15px', paddingBottom: '15px' }}>
            <Space>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Submit
              </Button>
              <Button htmlType="button" onClick={onReset}>
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Card>
      </Form>

      {showModalAddModel && !!currProvider && !!newModel && (
        <Modal
          open={true}
          title={'Add new model'}
          onCancel={(e) => {
            resetCurrentItem();
            setShowModalAddModel(false);
          }}
          onOk={async (e) => {
            await submitCreateNewModel();
          }}
          width={600}
        >
          <Form
            {...layout}
            form={formAddModel}
            // name="control-hooks"
            // onFinish={onSubmitAddNewModel}
            colon={false}
          >
            {['model', 'name', 'modelType', 'contextLength', 'providerId'].map((column: string, idxCol: number) => {
              return (
                <Form.Item key={idxCol} initialValue={(newModel as any)[column]} name={column} label={keyToTitle(column)} rules={[{ required: false }]} tooltip={'Input ' + keyToTitle(column)}>
                  <Input
                    defaultValue={(newModel as any)[column]}
                    value={(newModel as any)[column]}
                    type="text"
                    onChange={(e) => {
                      setNewModel({
                        ...newModel,
                        [column]: e.target.value,
                      });
                    }}
                    placeholder={'Input ' + keyToTitle(column)}
                    disabled={['providerId', 'modelType'].includes(column)}
                  />
                </Form.Item>
              );
            })}
          </Form>
        </Modal>
      )}

      {showModalViewModel && !!currProvider && !!viewModel && (
        <Modal
          open={showModalViewModel && !!currProvider && !!viewModel}
          title={'View model'}
          onCancel={(e) => {
            setShowModalViewModel(false);
            setCurrProvider(null as any);
            setViewModel(null as any);
          }}
          onOk={async (e) => {
            setShowModalViewModel(false);
          }}
        >
          {Object.keys(viewModel).map((columnK, idx) => {
            return (
              <Row key={idx}>
                <Col span={12}>{keyToTitle(columnK)}</Col>
                <Col span={12}>{(viewModel as any)[columnK]}</Col>
              </Row>
            );
          })}
        </Modal>
      )}
    </div>
  );
};

export default ApiKeyPage;
